import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; 

// Default to production project ID if env var is not set
const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID || 'stashpandas24';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || 'AIzaSyDVRYmeG8qKDTO7V2zm2DBikYmr4P7247M',
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || 'stashpandas24.firebaseapp.com',
  projectId: projectId,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || 'stashpandas24.appspot.com',
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || '543858628258',
  appId: import.meta.env.VITE_FIREBASE_APP_ID || '1:543858628258:web:1bf0bc1113065e4fa169fb',
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

let app;
let auth;
let db;
let functions;
let storage;

try {
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
  functions = getFunctions(app, 'us-central1');
  storage = getStorage(app);
} catch (error) {
  console.error('Firebase initialization error:', error);
}

export { app, auth, db, functions, storage };
