import { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { addProductToCart, updateCartItemQuantity } from '../../redux/actions/orderActions';

import ItemDetailsModal from "./ItemDetailsModal";
import BuyNowModal from "./BuyNowModal";
import PortalPopup from "./PortalPopup";
import BuyNowButton from "./BuyNowButton";
import AddToStashButton from "./AddToStashButton";
import QuantityCounter from "./QuantityCounter";
import defaultProductImage from '../../public/cherry-ak--1removebgpreview-1@2x.png'; // Adjust the path as needed

import "./ProductCard.css";

const storage = getStorage();

const categories = [
  { src: "/marijuana-1@2x.png", alt: "Flower", title: "Flower", className: "flower", category: "FLOWER" },
  { src: "/vaping-1-2@2x.png", alt: "Vapes", title: "Vapes", className: "vapes", category: "CARTRIDGE" },
  { src: "/chocolatebar-2@2x.png", alt: "Edibles", title: "Edibles", className: "edibles", category: "EDIBLE" },
  { src: "/man-1@2x.png", alt: "Pre-Rolls", title: "Pre-Rolls", className: "pre-rolls", category: "PREROLL" },
  { src: "/iodine-1@2x.png", alt: "Tinctures", title: "Tinctures", className: "extracts", category: "TINCTURE" },
];

const ProductCard = ({ 
  propWidth, 
  propAlignSelf, 
  id, 
  cartItemId,
  name, 
  price, 
  discountedPrice, 
  category,
  classification,
  brand, 
  sellableQuantity,
  imageUrl, 
  description,
  onLoginClick  }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.order.cart);
  const user = useSelector(state => state.user);

  const [isItemDetailsModalOpen, setItemDetailsModalOpen] = useState(false);
  const [isBuyNowModalOpen, setBuyNowModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addedToStash, setAddedToStash] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [imageUrlFix, setImageUrlFix] = useState('/default-product-image.png');
  const [isCategoryImage, setIsCategoryImage] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  console.log(cartItems);
  useEffect(() => {
    const cartItem = cartItems.find(item => item.id === id);
    if (cartItem) {
      setAddedToStash(true);
      setQuantity(cartItem.quantity);
    } else {
      setAddedToStash(false);
      setQuantity(0);
    }
  }, [cartItems, id]);

  useEffect(() => {
    setIsImageLoading(true);
    if (imageUrl && imageUrl !== "/default-product-image.png") {
      if (imageUrl.startsWith('gs://')) {
        const fetchImageUrl = async () => {
          try {
            const url = await getDownloadURL(ref(storage, imageUrl));
            setImageUrlFix(url);
            setIsCategoryImage(false);
          } catch (error) {
            console.error("Error fetching image URL:", error);
            setImageUrlFix(getCategoryImage(category));
            setIsCategoryImage(true);
          } finally {
            setIsImageLoading(false);
          }
        };
        fetchImageUrl();
      } else {
        setImageUrlFix(imageUrl);
        setIsCategoryImage(false);
        setIsImageLoading(false);
      }
    } else {
      setImageUrlFix(getCategoryImage(category));
      setIsCategoryImage(true);
      setIsImageLoading(false);
    }
  }, [imageUrl, category]);

  const getCategoryImage = (productCategory) => {
    if (!productCategory) {
      setIsCategoryImage(true);
      return defaultProductImage;
    }
    const categoryImage = categories.find(cat => cat.category.toLowerCase() === productCategory.toLowerCase());
    setIsCategoryImage(true);
    return categoryImage ? categoryImage.src : defaultProductImage;
  };

  const handleQuantityChange = (newQuantity) => {
    dispatch(updateCartItemQuantity(null, id, newQuantity));
  };

  const handleAddToStash = () => {
    dispatch(addProductToCart({
      id,
      name,
      price,
      discountedPrice,
      category,
      classification,
      brand,
      sellableQuantity,
      imageUrl: imageUrlFix,
      description
    }, 1));
    setAddedToStash(true);
  };

  const openItemDetailsModal = useCallback(() => {
    setItemDetailsModalOpen(true);
  }, []);

  const closeItemDetailsModal = useCallback(() => {
    setItemDetailsModalOpen(false);
  }, []);

  const openBuyNowModal = useCallback(() => {
    console.log('imageUrlFix', imageUrlFix);
    console.log('Opening BuyNow Modal with user:', user);
    setSelectedProduct({
      id,
      cartItemId,
      name,
      price,
      discountedPrice,
      category,
      classification,
      brand,
      sellableQuantity,
      imageUrl: imageUrlFix,
      description
    });
    setBuyNowModalOpen(true);
  }, [id, cartItemId, name, price, discountedPrice, category, classification, brand, sellableQuantity, imageUrlFix, description]);

  const closeBuyNowModal = useCallback(() => {
    console.log("Closing Buy Now Modal");
    setBuyNowModalOpen(false);
    setSelectedProduct(null); // Clear the selected product when closing the modal
  }, []);

  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth || '250px',
    };
  }, [propWidth]);

  const frameDiv1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const currentPrice = discountedPrice || price || 0;
  const originalPrice = price || 0;
  const discountPercentage = discountedPrice ? Math.round(((price - discountedPrice) / price) * 100) : 0;

  const lineageLabel = useMemo(() => {
    if (classification) {
      switch (classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: 'var(--color-deepskyblue-100)' };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: 'var(--color-sativa-yellow)' }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: 'var(--color-deeppink-300)' };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: 'var(--color-hybrid-green)'};
        default:
          return null;
      }
    }
    return null;
  }, [classification]);

  const productDetails = {
    id,
    name,
    price,
    discountedPrice,
    category,
    classification,
    brand,
    sellableQuantity,
    imageUrl: imageUrlFix,
    description
  };

  return (
    <div className={`product-card ${isImageLoading ? 'loading' : ''}`} style={frameDivStyle}>
      <div className="image-container">
        {isImageLoading ? (
          <div className="skeleton-image"></div>
        ) : (
          <div className="image-wrapper">
            <img
              className={`product-image ${isCategoryImage ? 'category-image' : ''}`}
              loading="lazy"
              alt={name || 'Product Image'}
              src={imageUrlFix}
              onLoad={() => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
            />
            <div className="price-label">{discountPercentage}% OFF</div>
            {lineageLabel && (
              <div 
                className="lineage-label" 
                style={{ backgroundColor: lineageLabel.backgroundColor }}
              >
                {lineageLabel.text}
              </div>
            )}
            <img
              className="expand-icon"
              src="/frame-1597890375.svg"
              alt="Expand"
              onClick={openItemDetailsModal}
            />
          </div>
        )}
      </div>
      
      <div className="product-info">
        <h3 className="product-name">{name}</h3>
        <div className="price-info">
          <span className="original-price">${originalPrice.toFixed(2)}</span>
          <span className="current-price">${currentPrice.toFixed(2)}</span>
        </div>
      </div>
      <div className="divider" />
      <div className="action-buttons">
        {user && user.email ? (
          <>
            <BuyNowButton buttonText="Buy Now" action={openBuyNowModal} />
            {addedToStash ? (
              <QuantityCounter 
                inventoryCount={sellableQuantity} 
                onQuantityChange={handleQuantityChange}
                initialQuantity={quantity}
                productId={id}
              />
            ) : (
              <AddToStashButton 
                title="Add to Stash" 
                action={handleAddToStash}
              />
            )}
          </>
        ) : (
          <AddToStashButton 
            title="Login to Buy" 
            action={onLoginClick}
          />
        )}
      </div>
      {isItemDetailsModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeItemDetailsModal}
        >
          <ItemDetailsModal product={productDetails} onClose={closeItemDetailsModal} />
        </PortalPopup>
      )}
      {isBuyNowModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeBuyNowModal}
        >
          <BuyNowModal 
            product={selectedProduct} 
            onClose={closeBuyNowModal}
            onOpenItemDetails={openItemDetailsModal}
          />   
      </PortalPopup>
      )}
    </div>
  );
};

export default ProductCard;
