import { useDispatch } from 'react-redux';
import { addProductToCart } from '../../redux/actions/orderActions';

import "./AddToStashButton.css";

const AddToStashButton = ({ title, action }) => {

  return (
    <div className="add-to-stash-container" onClick={action}>
      <div className="add-to-stash1">{title}</div>
    </div>
  );
};

export default AddToStashButton;

