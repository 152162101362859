import "./LoginButton.css";

const LoginButton = ({ onClick, user }) => {
  const buttonText = user && user.email ? "Log Out" : "Login";

  return (
    <button className="data-sinks" onClick={() => {
      console.log('LoginButton clicked', onClick); // Add logging to verify button click
      onClick();
    }}>
      <div className="login">{buttonText}</div>
    </button>
  );
};

export default LoginButton;
