import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './VerificationForm.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const VerificationForm = ({ onVerificationSuccess }) => {
  const DEFAULT_STATE = 'CA';
  const user = useSelector(state => state.user);

  // Calculate default date (exactly 21 years ago)
  const getDefaultDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 21);
    return date;
  };
  
  const [formData, setFormData] = useState({
    fullName: user.accountName || '',
    birthDate: user.birthday ? new Date(user.birthday) : getDefaultDate(),
    phoneNumber: user.phoneNumber || '',
    idType: user.idType || "Driver's License",
    licenseState: user.licenseState || DEFAULT_STATE,
    idNumber: user.idNumber || ''
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [idError, setIdError] = useState('');
  const [showIdFormat, setShowIdFormat] = useState(true);
  const [dateError, setDateError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  // Calculate date limits
  const getDateLimits = () => {
    const today = new Date();
    
    // Minimum date (must be 21 years old)
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 21);
    
    // Maximum date (cannot be over 125 years old)
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() - 125);
    
    return { minDate, maxDate };
  };

  const { minDate, maxDate } = getDateLimits();

  const validateDate = (date) => {
    if (!date) {
      setDateError('Please select your birth date');
      return false;
    }

    const today = new Date();
    const birthDate = new Date(date);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    // Adjust age if birthday hasn't occurred this year
    const finalAge = monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate()) 
      ? age - 1 
      : age;

    if (finalAge < 21) {
      setDateError('You must be 21 or older');
      return false;
    }

    if (finalAge > 125) {
      setDateError('Please enter a valid birth date');
      return false;
    }

    setDateError('');
    return true;
  };

  const validateFullName = (name) => {
    const nameWords = name.trim().split(/\s+/);
    if (nameWords.length < 2) {
      setNameError('Please enter your full name (first and last name)');
      return false;
    }

    const validNameRegex = /^[A-Za-z\s'-]+$/;
    if (!validNameRegex.test(name)) {
      setNameError('Please use only letters, spaces, hyphens, and apostrophes');
      return false;
    }

    if (!nameWords.every(word => word.length >= 2)) {
      setNameError('Each name must be at least 2 characters long');
      return false;
    }

    setNameError('');
    return true;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const numericPhone = phoneNumber.replace(/[^\d]/g, '');
    const isValid = numericPhone.length === 10;
    if (!isValid) {
      setPhoneError('Please enter a valid 10-digit phone number');
    } else {
      setPhoneError('');
    }
    return isValid;
  };

  // State-specific license formats
  const LICENSE_FORMATS = {
    'CA': { 
      pattern: /^[A-Z]\d{7}$/,
      description: '1 letter followed by 7 numbers (e.g., A1234567)'
    },
    'NY': { 
      pattern: /^[A-Z]\d{7}$/,
      description: '1 letter followed by 7 numbers (e.g., A1234567)'
    },
    'TX': { 
      pattern: /^\d{7,8}$/,
      description: '7 or 8 numbers'
    },
    'FL': { 
      pattern: /^[A-Z]\d{12}$/,
      description: '1 letter followed by 12 numbers'
    },
    'default': {
      pattern: /^[A-Z0-9]{6,12}$/,
      description: '6-12 characters (letters and numbers)'
    }
  };

  const validateIdNumber = (idNumber, state) => {
    if (!state) return false;
    
    const format = LICENSE_FORMATS[state] || LICENSE_FORMATS.default;
    const isValid = format.pattern.test(idNumber.toUpperCase());
    
    if (!isValid && idNumber.length > 0) {
      setIdError(`Please enter a valid ${state} driver's license: ${format.description}`);
    } else if (!isValid && showIdFormat) {
      setIdError(`Format required: ${format.description}`);
    } else {
      setIdError('');
    }
    
    return isValid;
  };

  const formatFullName = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    const allFieldsFilled = Object.entries(formData).every(([key, value]) => {
      if (key === 'phoneNumber') {
        return validatePhoneNumber(value);
      }
      if (key === 'fullName') {
        return validateFullName(value);
      }
      if (key === 'idNumber') {
        return validateIdNumber(value, formData.licenseState);
      }
      if (key === 'birthDate') {
        return validateDate(value);
      }
      if (key === 'idType' || key === 'licenseState') {
        return value !== '';
      }
      return value !== null && value.trim() !== '';
    });
    
    setIsFormValid(allFieldsFilled);
  }, [formData]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      fullName: user.accountName || prev.fullName,
      birthDate: user.birthday ? new Date(user.birthday) : prev.birthDate,
      phoneNumber: user.phoneNumber || prev.phoneNumber,
      idType: user.idType || prev.idType,
      licenseState: user.licenseState || prev.licenseState,
      idNumber: user.idNumber || prev.idNumber
    }));
  }, [user]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    
    if (phoneNumberLength < 1) return '';
    if (phoneNumberLength < 4) {
      return `(${phoneNumber}`;
    }
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleFullNameChange = (e) => {
    const { value } = e.target;
    if (value === '' || /^[A-Za-z\s'-]*$/.test(value)) {
      if (value.length <= 50) {  
        const formattedName = formatFullName(value);
        setFormData(prevState => ({
          ...prevState,
          fullName: formattedName
        }));
        validateFullName(formattedName);
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    if (value.replace(/[^\d]/g, '').length <= 10) {
      const formattedNumber = formatPhoneNumber(value);
      setFormData(prevState => ({
        ...prevState,
        phoneNumber: formattedNumber
      }));
      validatePhoneNumber(formattedNumber);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      // Ensure the time is set to midnight UTC to avoid timezone issues
      const normalizedDate = new Date(date.setHours(0, 0, 0, 0));
      setFormData(prevState => ({
        ...prevState,
        birthDate: normalizedDate
      }));
      validateDate(normalizedDate);
    } else {
      setFormData(prevState => ({
        ...prevState,
        birthDate: null
      }));
      setDateError('Please select your birth date');
    }
  };

  const handleIdNumberChange = (e) => {
    const { value } = e.target;
    if (value === '' || /^[A-Za-z0-9]*$/.test(value)) {
      if (value.length <= 15) {
        const newValue = value.toUpperCase();
        setFormData(prevState => ({
          ...prevState,
          idNumber: newValue
        }));
        validateIdNumber(newValue, formData.licenseState);
      }
    }
  };

  const handleIdNumberFocus = () => {
    setShowIdFormat(true);
    if (formData.licenseState) {
      const format = LICENSE_FORMATS[formData.licenseState] || LICENSE_FORMATS.default;
      setIdError(`Format required: ${format.description}`);
    }
  };

  const handleIdNumberBlur = () => {
    if (!formData.idNumber) {
      setShowIdFormat(false);
      setIdError('');
    } else {
      validateIdNumber(formData.idNumber, formData.licenseState);
    }
  };

  const handleStateChange = (e) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      licenseState: value,
      idNumber: ''
    }));
    
    if (value) {
      const format = LICENSE_FORMATS[value] || LICENSE_FORMATS.default;
      setIdError(`Format required: ${format.description}`);
      setShowIdFormat(true);
    } else {
      setIdError('');
      setShowIdFormat(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid || isSubmitting) return;

    setIsSubmitting(true);
    setSubmitError('');

    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        setSubmitError('User not authenticated. Please sign in again.');
        return;
      }

      const functions = getFunctions();
      const updateUserInfo = httpsCallable(functions, 'updateUserInfo');

      const { data } = await updateUserInfo({
        userId,
        accountName: formData.fullName,
        birthday: formData.birthDate.toISOString(),
        phoneNumber: formData.phoneNumber,
        idType: formData.idType,
        licenseState: formData.licenseState,
        idNumber: formData.idNumber
      });

      if (data.success) {
        if (onVerificationSuccess) {
          onVerificationSuccess();
        }
      } else {
        setSubmitError(data.message || 'Failed to update user information');
      }
    } catch (error) {
      console.error('Error updating user info:', error);
      setSubmitError(error.message || 'An error occurred while updating your information. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  return (
    <div className="verification-form-container">
      <h3>Account Verification Required</h3>
      <p>Please provide your information to verify your account.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleFullNameChange}
            placeholder="Full Name"
            required
            className={`form-input ${nameError ? 'error' : ''}`}
            maxLength="50"
            pattern="^[A-Za-z\s'-]+$"
            title="Please enter your full name using only letters, spaces, hyphens, and apostrophes"
          />
          {nameError && <div className="error-message">{nameError}</div>}
        </div>
        <div className="form-group">
          <label className="form-label">Birth Date</label>
          <DatePicker
            wrapperClassName="datepicker-wrapper"
            selected={formData.birthDate}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            maxDate={minDate}
            minDate={maxDate}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="Date of Birth"
            className="form-input"
            required
            openToDate={formData.birthDate || new Date(minDate.getFullYear() - 10, 0, 1)}
            onCalendarOpen={() => setDateError('')}
            isClearable={false}
            customInput={<input className="form-input" />}
          />
          {dateError && <div className="error-message">{dateError}</div>}
        </div>
        <div className="form-group">
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Phone Number"
            pattern="\(\d{3}\) \d{3}-\d{4}"
            title="Please enter a valid US phone number in the format: (XXX) XXX-XXXX"
            required
            className={`form-input ${phoneError ? 'error' : ''}`}
            maxLength="14"
          />
          {phoneError && <div className="error-message">{phoneError}</div>}
        </div>
        <div className="form-group">
          <select
            name="idType"
            value={formData.idType}
            onChange={handleChange}
            required
            className="form-input"
          >
            <option value="Driver's License">Driver's License</option>
          </select>
        </div>
        <div className="form-group">
          <select
            name="licenseState"
            value={formData.licenseState}
            onChange={handleStateChange}
            required
            className="form-input"
          >
            {states.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="idNumber"
            value={formData.idNumber}
            onChange={handleIdNumberChange}
            onFocus={handleIdNumberFocus}
            onBlur={handleIdNumberBlur}
            placeholder={`${formData.licenseState} Driver's License Number`}
            required
            className={`form-input ${idError && formData.idNumber ? 'error' : ''}`}
            maxLength="15"
          />
          {idError && <div className="error-message">{idError}</div>}
        </div>
        <button
          type="submit"
          className="purchase"
          disabled={!isFormValid || isSubmitting}
        >
          {isSubmitting ? (
            <div className="spinner" />
          ) : (
            'Update Account'
          )}
        </button>
        {submitError && (
          <div className="error-message submit-error">{submitError}</div>
        )}
      </form>
    </div>
  );
};

export default VerificationForm;
