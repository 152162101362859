import React from 'react';
import ProductImageSmall from "./ProductImageSmall";
import { useDispatch } from 'react-redux';
import { updateCartItemQuantity } from '../../redux/actions/orderActions';
import QuantityCounter from "./QuantityCounter";
import "./StashItem.css";

const StashItem = ({ 
  id, 
  name, 
  brand, 
  category, 
  classification, 
  price, 
  discountedPrice, 
  quantity, 
  imageUrl, 
  sellableQuantity, 
  purchasedStatus,
  readOnly 
}) => {
  const dispatch = useDispatch();

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > 0) {
      dispatch(updateCartItemQuantity(null, id, newQuantity));
    }
  };

  const formatPrice = (value) => {
    return typeof value === 'number' ? value.toFixed(2) : '0.00';
  };

  return (
    <div className="stash-item">
      <div className="stash-item__content">
        <div className="stash-item__image">
          <ProductImageSmall imageUrl={imageUrl} />
        </div>
        
        <div className="stash-item__details">
          <h3 className="stash-item__name">{name || 'Unknown Product'}</h3>
          <div className="stash-item__brand">{brand || 'Unknown Brand'}</div>
          <div className="stash-item__category">
            {`${category || 'Unknown Category'} (${classification || 'Unknown Classification'})`}
          </div>
          <div className="stash-item__price-container">
            <div className="stash-item__price">${formatPrice(discountedPrice || price)}</div>
            {discountedPrice && price && discountedPrice < price && (
              <div className="stash-item__original-price">${formatPrice(price)}</div>
            )}
          </div>
        </div>
      </div>

      <div className="stash-item__controls">
        {readOnly ? (
          <div className="stash-item__quantity-display">
            Qty: {quantity}
          </div>
        ) : purchasedStatus !== "purchased" && (
          <div className="stash-item__quantity-section">
            <QuantityCounter 
              inventoryCount={sellableQuantity} 
              onQuantityChange={handleQuantityChange}
              initialQuantity={quantity}
              productId={id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StashItem;
