const initialState = {
  paymentMethods: [],
  defaultPaymentMethod: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PAYMENT_METHODS_SUCCESS':
      return { ...state, paymentMethods: action.payload };
    case 'GET_PAYMENT_METHODS_FAIL':
      // Store only the error message or a serializable part of the error
      return { ...state, error: action.payload.message || 'An error occurred' };

    case 'ATTACH_PAYMENT_METHOD_SUCCESS':
      return { ...state, paymentMethods: [...state.paymentMethods, action.payload] };
    case 'ATTACH_PAYMENT_METHOD_FAIL':
      // Store only the error message or a serializable part of the error
      return { ...state, error: action.payload.message || 'Failed to attach payment method' };

    case 'DETACH_PAYMENT_METHOD_SUCCESS':
      return { ...state, paymentMethods: state.paymentMethods.filter(pm => pm.id !== action.payload.id) };
    case 'DETACH_PAYMENT_METHOD_FAIL':
      // Store only the error message or a serializable part of the error
      return { ...state, error: action.payload.message || 'Failed to detach payment method' };

    case 'SET_DEFAULT_PAYMENT_METHOD_SUCCESS':
      return { ...state, defaultPaymentMethod: action.payload };
    case 'SET_DEFAULT_PAYMENT_METHOD_FAIL':
      // Store only the error message or a serializable part of the error
      return { ...state, error: action.payload.message || 'Failed to set default payment method' };

    default:
      return state;
  }
};