import { useState, useEffect } from "react";
import "./CategorySelect.css";

const categories = [
  { src: "/marijuana-1@2x.png", alt: "Flower", title: "Flower", className: "flower", productType: "FLOWER" },
  { src: "/vaping-1-2@2x.png", alt: "Vapes", title: "Vapes", className: "vapes", productType: "CARTRIDGE" },
  { src: "/chocolatebar-2@2x.png", alt: "Edibles", title: "Edibles", className: "edibles", productType: "EDIBLE" },
  { src: "/man-1@2x.png", alt: "Pre-Rolls", title: "Pre-Rolls", className: "pre-rolls", productType: "PREROLL" },
  { src: "/iodine-1@2x.png", alt: "Tinctures", title: "Tinctures", className: "extracts", productType: "TINCTURE" },
];

const CategorySelect = ({ setSelectedProductType, seeAll, setSeeAll, handleBackClick, products }) => {
  const [selectedCategory, setSelectedCategory] = useState('FLOWER');

  // Function to check if a category has available products
  const hasAvailableProducts = (productType) => {
    return products.some(product => 
      product.sellableQuantity > 0 && product.category === productType
    );
  };

  // Function to find the first available category
  const findFirstAvailableCategory = () => {
    for (const category of categories) {
      if (hasAvailableProducts(category.productType)) {
        return category.productType;
      }
    }
    return categories[0].productType; // Default to first category if none have products
  };

  // Effect to handle category fallback
  useEffect(() => {
    if (products.length > 0 && !hasAvailableProducts(selectedCategory)) {
      const availableCategory = findFirstAvailableCategory();
      setSelectedCategory(availableCategory);
      setSelectedProductType(availableCategory);
    }
  }, [products, selectedCategory, setSelectedProductType]);

  const handleCategoryClick = (category) => {
    if (hasAvailableProducts(category.productType)) {
      setSelectedProductType(category.productType);
      setSelectedCategory(category.productType);
    } else {
      // If clicked category has no products, find first available
      const availableCategory = findFirstAvailableCategory();
      setSelectedProductType(availableCategory);
      setSelectedCategory(availableCategory);
    }
  };

  return (
    <div className="category-select-container">
      {seeAll && (
        <div className="back-arrow" onClick={handleBackClick}>
          ← Back
        </div>
      )}
      <div className="category-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`category-item ${category.className} ${selectedCategory === category.productType ? 'selected' : ''} ${!hasAvailableProducts(category.productType) ? 'disabled' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            <div className="shine-container">
              <div className="shine-1"></div>
              <div className="shine-2"></div>
            </div>
            <img
              className="category-icon"
              loading="lazy"
              alt={category.alt}
              src={category.src}
            />
            <h3 className="category-title">{category.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySelect;
