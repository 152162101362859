import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from '../redux/actions/orderActions';
import { setupUserListener } from '../utils/FirebaseUtils';
import HomeScreen from "./pages/HomeScreen";
import QRTracker from "./components/QRTracker";
import SplashPage from "./pages/SplashPage";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (user && user.userId) {
      dispatch(fetchCart(user.userId));
      
      // Setup real-time user data listener
      const unsubscribe = setupUserListener(user.userId);
      
      // Cleanup listener on unmount or when user changes
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [dispatch, user.userId]);

  return (
    <>
      <QRTracker />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
      </Routes>
    </>
  );
}

export default App;
