import * as actionTypes from '../actions/actionTypes';

const initialState = {
  email: '',
  userId: '',
  stripeCustomerId: '',
  role: '',
  accountName: '',
  legalName: '',
  birthday: '',
  purchaseStatus: '',
  idType: '',
  licenseState: '',
  idNumber: '',
  phoneNumber: '',
  photoUrl: '',
  createdAt: null,
  lastUpdatedAt: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      // Ensure that action.payload is not null before trying to access its properties
      if (action.payload) {
        return {
          ...state,
          email: action.payload.email || '',
          userId: action.payload.userId || '',
          stripeCustomerId: action.payload.stripeCustomerId || '',
          role: action.payload.role || '',
          accountName: action.payload.accountName || '',
          legalName: action.payload.legalName || '',
          birthday: action.payload.birthday || '',
          purchaseStatus: action.payload.purchaseStatus || '',
          idType: action.payload.idType || '',
          licenseState: action.payload.licenseState || '',
          idNumber: action.payload.idNumber || '',
          phoneNumber: action.payload.phoneNumber || '',
          photoUrl: action.payload.photoUrl || '',
          createdAt: action.payload.createdAt || null,
          lastUpdatedAt: action.payload.lastUpdatedAt || null,
        };
      }
      // If payload is null, return the current state
      return state;
    case actionTypes.CLEAR_USER:
      return {
        ...initialState, 
      };
    default:
      return state;
  }
};

export default userReducer;
