import React, { useState } from 'react';
import "./AuthModal.css";
import ModalHeader from "./ModalHeader"; // Import the ModalHeader component
import Input from "./Input"; // Import the Input component
import BuyNowButton from "./BuyNowButton"; // Import the BuyNowButton component
import AddToStashButton from "./AddToStashButton"; // Import the AddToStashButton component
import AuthService from "../../utils/AuthService";

const AuthModal = ({ onClose }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async () => {
    console.log('signing in')
    setIsLoading(true);
    try {
      await AuthService.signIn(email, password);
      onClose(); // Close the auth modal on successful sign-in
    } catch (error) {
      console.log(error);
      window.alert('Sign In Failed', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async () => {
    if (password === confirmPassword) {
      setIsLoading(true);
      try {
        await AuthService.signUp(email, password);
        onClose(); // Close the auth modal on successful sign-up and sign-in
      } catch (signUpError) {
        console.error('Sign Up Error:', signUpError);
        window.alert('Sign Up Failed', signUpError.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      alert('Passwords do not match!');
    }
  };

  return (
    <div className="auth-modal">
      <ModalHeader onClose={onClose} title="Stash Pandas" /> {/* Use the ModalHeader component */}
      <div className="modal-content">
        {isLoading ? (
          <div className="auth-modal-spinner-container">
            <div className="auth-modal-spinner"></div>
          </div>
        ) : (
          <>
            <div className="inputs-container">
              <div className="auth-header">
                {isSignUp ? "Create Account" : "Log In"}
              </div>
              <Input
                className="input-field"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                className="input-field"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {isSignUp && (
                <Input
                  className="input-field"
                  placeholder="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              )}
            </div>
            <div className="buttons-container">
              <BuyNowButton buttonText={isSignUp ? "Sign Up" : "Sign In"} action={isSignUp ? handleSignUp : handleSignIn} />
              <AddToStashButton title={isSignUp ? "Sign In" : "Sign Up"} action={() => setIsSignUp(!isSignUp)} />
              <button className="reset-password-button">Reset Password</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthModal;

