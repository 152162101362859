// rootReducer.js
import { combineReducers } from 'redux';

import userReducer from './userReducer';
import stripeReducer from './stripeReducer';
import orderReducer from './orderReducer';

const rootReducer = combineReducers({
  user: userReducer,
  stripe: stripeReducer,
  order: orderReducer,
});

export default rootReducer;
