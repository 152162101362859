import "./ModalHeader.css";

const ModalHeader = ({ onClose, title }) => {
  return (
    <div className="big-logo-traced-group">
      <img
        className="big-logo-traced3"
        loading="lazy"
        alt=""
        src="/big-logo-traced.svg"
      />
      <div className="your-stash-wrapper">
        <div className="your-stash">{title}</div>
      </div>
      <div className="vuesaxlinearclose-circle-wrapper" onClick={onClose}>
        <img
          className="vuesaxlinearclose-circle-icon2"
          loading="lazy"
          alt=""
          src="/vuesaxlinearclosecircle2.svg"
        />
      </div>
    </div>
  );
};

export default ModalHeader;
