import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp, getDoc, doc, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import { IonIcon } from '@ionic/react';
import { timeOutline, bagOutline } from 'ionicons/icons';

import { removeFromCart, createOrder, fetchOrders } from '../../redux/actions/orderActions';
import ModalHeader from "./ModalHeader";
import StashItem from "./StashItem";
import OrderDetails from "./OrderDetails";
import LocationFull from "./LocationFull";
import VerificationForm from "./VerificationForm";
import { previewTreezTicket, createTreezTicket } from '../../utils/TreezService';
import OrderItem from './OrderItem';

import "./StashModal.css";

const StashModal = ({ onClose, products, onPurchaseComplete, initialMode = 'stash' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const [orderStatuses, setOrderStatuses] = useState({});

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const cartItems = useSelector(state => state.order.cart);
  const { orders } = useSelector(state => state.order);

  // Initial fetch using Redux
  useEffect(() => {
    if (user && user.userId) {
      dispatch(fetchOrders(user.userId));
    }
  }, [dispatch, user]);

  // Set up real-time listeners for order status updates
  useEffect(() => {
    if (!orders || orders.length === 0) return;

    console.log('Setting up listeners for orders:', orders.map(o => o.id));
    
    // Create a listener for each order
    const unsubscribes = orders.map(order => {
      if (!order.id) return null;

      return onSnapshot(
        doc(db, 'orders', order.id),
        {
          next: (docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              console.log('Order status update:', order.id, data.orderStatus);
              
              setOrderStatuses(prev => ({
                ...prev,
                [order.id]: data.orderStatus
              }));
            }
          },
          error: (error) => {
            console.error('Error in order listener:', error);
          }
        }
      );
    }).filter(Boolean);

    // Cleanup listeners on unmount or when orders change
    return () => {
      console.log('Cleaning up order listeners');
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [orders]);

  // Initialize liveOrders with Redux orders
  useEffect(() => {
    if (orders.length > 0) {
      setOrderStatuses({});
    }
  }, [orders]);

  // Use live orders if available, fall back to Redux orders
  const displayOrders = orders;

  const handleRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  useEffect(() => {
    // Add modal-open class to body when component mounts
    document.body.classList.add('modal-open');

    // Clean up function to remove class when component unmounts
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []); // Empty dependency array means this runs once on mount

  const handlePurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: cartItems.map(item => ({
          location_name: "STASHPANDA",
          size_id: item.id,
          quantity: item.quantity,
        })),
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
        scheduled_date: new Date().toISOString(),
      };
      const result = await createTreezTicket(ticketData);
      console.log("[StashModal] Creating Treez ticket:", result);
      if (result.resultCode === 'SUCCESS') {
        const totalAmount = cartItems.reduce((total, item) => {
          return total + parseFloat((item.price * item.quantity).toFixed(2));
        }, 0);
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(), // Use ISO string
          totalAmount: totalAmount,
          items: cartItems.map(item => ({
            productId: item.id,
            quantity: item.quantity,
            price: item.price,
          })),
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: result.data?.ticket_id || '',
          treezOrderNumber: result.data?.order_number || '',
          treezOrderStatus: result.data?.order_status || '',
          treezPaymentStatus: result.data?.payment_status || '',
        };

        const orderId = await dispatch(createOrder(orderData, result));
        console.log("Order successfully created in Firestore with ID:", orderId);

        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        onPurchaseComplete();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      console.error("Error creating ticket:", error);
      setError("Failed to create ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewPurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const ticketData = {
        type: "PICKUP",
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: cartItems.map(item => ({
          location_name: "STASHPANDA",
          size_id: item.id,
          quantity: item.quantity,
        })),
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,        
        scheduled_date: new Date().toISOString(),
      };
      const previewResult = await previewTreezTicket(ticketData);
      console.log("[StashModal] Previewing Treez ticket:", previewResult);
      if (previewResult.resultCode === 'SUCCESS') {
        const totalAmount = cartItems.reduce((total, item) => {
          return total + parseFloat((item.price * item.quantity).toFixed(2));
        }, 0);
        
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: new Date().toISOString(), // Use ISO string
          totalAmount: totalAmount,
          items: cartItems.map(item => ({
            productId: item.id,
            quantity: item.quantity,
            price: item.price,
          })),
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
          treezTicketId: previewResult.data?.ticket_id || '',
          treezOrderNumber: previewResult.data?.order_number || '',
          treezOrderStatus: previewResult.data?.order_status || '',
          treezPaymentStatus: previewResult.data?.payment_status || '',
        };

        const orderId = await dispatch(createOrder(orderData, previewResult));
        console.log("Order successfully created in Firestore with ID:", orderId);

        for (const item of cartItems) {
          await dispatch(removeFromCart(user.userId, item.id));
        }

        setMode('history');
        onPurchaseComplete();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
    } catch (error) {
      console.error("Error previewing ticket:", error);
      setError("Failed to preview ticket. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyAccount = (formData) => {
    // For now, just log the form data
    console.log('Verification form submitted:', formData);
    // TODO: Implement the actual verification logic
    handleVerificationSuccess();
  };

  const handleVerificationSuccess = () => {
    setPurchaseStatus(true);
    setShowVerificationForm(false);
  };

  const handleShowVerificationForm = () => {
    setShowVerificationForm(true);
  };

  const renderNavIcons = () => (
    <div className="nav-icons-container">
      <div style={{ width: '40px' }}>
        <IonIcon
          icon={timeOutline}
          className={`nav-icon ${mode === 'history' ? 'active' : ''}`}
          onClick={() => setMode('history')}
        />
      </div>
      <div style={{ width: '40px' }}>
        <IonIcon
          icon={bagOutline}
          className={`nav-icon ${mode === 'stash' ? 'active' : ''}`}
          onClick={() => setMode('stash')}
        />
      </div>
    </div>
  );

  const OrderProgress = ({ status }) => {
    const stages = [
      'VERIFICATION_PENDING',
      'AWAITING_PROCESSING',
      'IN_PROCESS',
      'PACKED_READY',
      'COMPLETED'
    ];

    const getStageIndex = (currentStatus) => {
      switch (currentStatus) {
        case 'VERIFICATION_PENDING':
          return 0;
        case 'AWAITING_PROCESSING':
          return 1;
        case 'IN_PROCESS':
          return 2;
        case 'PACKED_READY':
          return 3;
        case 'COMPLETED':
          return 4;
        case 'CANCELED':
          return -1;
        default:
          return -1;
      }
    };

    const currentIndex = getStageIndex(status);

    const getStatusLabel = (status) => {
      switch (status) {
        case 'VERIFICATION_PENDING':
          return 'Verification Needed';
        case 'AWAITING_PROCESSING':
          return 'Awaiting Confirmation';
        case 'IN_PROCESS':
          return 'Preparing Order';
        case 'PACKED_READY':
          return 'Ready for Pickup';
        case 'OUT_FOR_DELIVERY':
          return 'Out for Delivery';
        case 'COMPLETED':
          return 'Completed';
        case 'CANCELED':
          return 'Canceled';
        default:
          return 'Processing';
      }
    };

    // For canceled orders, show only the status label
    if (status === 'CANCELED') {
      return (
        <div className="order-progress">
          <div className="status-label canceled">
            {getStatusLabel(status)}
          </div>
        </div>
      );
    }

    // For completed orders, show all bars filled
    if (status === 'COMPLETED') {
      return (
        <div className="order-progress">
          <div className="progress-bars">
            {stages.slice(0, -1).map((stage, index) => (
              <React.Fragment key={stage}>
                <div className="progress-step-container">
                  <div className="progress-step completed" />
                </div>
                <div className="progress-bar-container">
                  <div className="progress-bar completed" />
                </div>
              </React.Fragment>
            ))}
            <div className="progress-step-container">
              <div className="progress-step completed" />
            </div>
          </div>
          <div className="status-label completed">
            {getStatusLabel(status)}
          </div>
        </div>
      );
    }

    return (
      <div className="order-progress">
        <div className="progress-bars">
          {stages.slice(0, -1).map((stage, index) => {
            const isCompleted = currentIndex > index;
            const isActive = currentIndex === index;
            return (
              <React.Fragment key={stage}>
                <div className="progress-step-container">
                  <div className={`progress-step ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`} />
                </div>
                <div className="progress-bar-container">
                  <div className={`progress-bar ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`} />
                </div>
              </React.Fragment>
            );
          })}
          <div className="progress-step-container">
            <div className={`progress-step ${status === 'COMPLETED' ? 'completed' : ''}`} />
          </div>
        </div>
        <div className={`status-label ${status === 'COMPLETED' ? 'completed' : 'active'}`}>
          {getStatusLabel(status)}
        </div>
      </div>
    );
  };

  const OrderBox = ({ order }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    const ticketData = order.ticketData || {};
    const isCompletedOrCanceled = order.orderStatus === 'COMPLETED' || order.orderStatus === 'CANCELED';
    
    return (
      <div className={`order-box ${isCompletedOrCanceled ? 'completed-or-canceled' : ''}`}>
        <div className="order-header">
          <div className="header-content">
            <div className="order-date">{formatDate(ticketData.scheduled_date)}</div>
            <div className="order-total">${(ticketData.totalAmount || 0).toFixed(2)}</div>
          </div>
        </div>
        <div className="order-summary">
          {isCompletedOrCanceled ? (
            <div className={`status-label ${order.orderStatus.toLowerCase()}`}>
              {order.orderStatus === 'COMPLETED' ? 'Completed' : 'Canceled'}
            </div>
          ) : (
            <OrderProgress status={order.orderStatus} />
          )}
        </div>
        <div className="expand-button-container">
          <button className={`expand-button ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
              <path d="M1 1L6 6L11 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </button>
        </div>
        {isExpanded && (
          <div className="order-details">
            <div className="order-id">Order #{order.treezOrderNumber || order.externalOrderNumber}</div>
            <div className="order-items">
              {order.items && Object.entries(order.items).map(([key, item]) => (
                <div className="order-stash-item-wrapper">
                  <StashItem
                    key={key}
                    name={item.name}
                    brand={item.brand}
                    type={item.category}
                    price={item.price}
                    imageUrl={item.imageUrl}
                    quantity={item.quantity}
                    readOnly={true}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const calculateOrderTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((total, item) => {
      if (!item) return total;
      const itemPrice = item.discountedPrice || item.price || 0;
      const quantity = item.quantity || 0;
      return total + (itemPrice * quantity);
    }, 0);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const renderStashContent = () => (
    <>
      <ModalHeader title="Your Stash" onClose={onClose} />
      {!showVerificationForm ? (
        <>
          <div className="stashmodal-child" />
          {renderNavIcons()}
          {cartItems.length === 0 ? (
            <div className="empty-cart-message">
              Your stash is empty. Add some items to make a purchase!
            </div>
          ) : (
            cartItems.map((item) => {
              const product = products.find(p => p.id === item.id);
              return (
                <StashItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  brand={item.brand}
                  category={item.category}
                  classification={item.classification}
                  price={item.price}
                  discountedPrice={item.discountedPrice}
                  quantity={item.quantity}
                  imageUrl={imageUrls[item.id]}
                  sellableQuantity={product ? product.sellableQuantity : Infinity}
                  onRemove={() => handleRemoveFromCart(item.id)}
                />
              );
            })
          )}
          <div className="stashmodal-inner" />
          <section className="stashmodal-inner1">
            <div className="box-parent">
              <OrderDetails cartItems={cartItems} products={products} />
              <LocationFull />
            </div>
          </section>
          <div className="stashmodal-button-container">
            <div 
              className={`purchase-wrapper ${cartItems.length === 0 ? 'disabled' : ''}`} 
              onClick={() => {
                if (cartItems.length > 0) {
                  user.purchaseStatus ? handlePurchase() : setShowVerificationForm(true);
                }
              }}
            >
              <div className="purchase">
                {isLoading ? 'Processing...' : 
                 cartItems.length === 0 ? 'Add items to purchase' :
                 user.purchaseStatus ? 'Purchase' : 'Verify Account'}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="verification-form-wrapper">
          <VerificationForm onSubmit={handleVerifyAccount} onVerificationSuccess={handleVerificationSuccess} />
        </div>
      )}
    </>
  );

  const renderOrderContent = () => {
    if (showVerificationForm) {
      return <VerificationForm onSubmit={handleVerifyAccount} onVerificationSuccess={handleVerificationSuccess} />;
    }

    return (
      <>
        <section className="frame-parent9">
          <div className="frame-parent10">
            <OrderDetails cartItems={cartItems} products={products} />
            <LocationFull />
          </div>
        </section>
        <div className="stashmodal-inner2">
          <div className="purchase-wrapper" onClick={() => user.purchaseStatus ? handlePurchase() : setShowVerificationForm(true)}>
            <div className="purchase">
              {user.purchaseStatus ? 'Purchase' : 'Verify Account to Purchase'}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderHistoryContent = () => {
    if (!orders || orders.length === 0) {
      return (
        <>
          <ModalHeader title="Order History" onClose={onClose} />
          <div className="order-history-container">
            <div className="no-orders-message">No orders found</div>
          </div>
        </>
      );
    }

    const sortedOrders = [...orders].sort((a, b) => {
      const dateA = a.orderTime ? new Date(a.orderTime) : new Date();
      const dateB = b.orderTime ? new Date(b.orderTime) : new Date();
      return dateB - dateA;
    });

    return (
      <>
        <ModalHeader title="Order History" onClose={onClose} />
        <div className="stashmodal-child" />
        {renderNavIcons()}
        <div className="order-history-container">
          {sortedOrders.map((order) => {
            if (!order) return null;
            
            const orderDate = order.orderTime ? new Date(order.orderTime) : new Date();
            const totalAmount = order.ticketData?.totalAmount || 0;
            
            // Use the real-time status if available, otherwise fall back to the order's status
            const currentStatus = orderStatuses[order.id] || order.orderStatus;
            
            return (
              <OrderBox key={order.id || order.treezTicketId || Math.random()} order={order} />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="stashmodal">
        {mode === 'stash' ? renderStashContent() : 
         mode === 'order' ? renderOrderContent() : 
         renderHistoryContent()}
      </div>
    </div>
  );
};

export default StashModal;
