import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { fetchTreezProducts } from '../../utils/TreezService';
import { getProductDetails } from '../../utils/FirebaseUtils';
import HomeHero from "../components/HomeHero";
import CategorySelect from "../components/CategorySelect";
import ProductGallery from "../components/ProductGallery";
import SpotlightBanner from "../components/SpotlightBanner";
import Footer from "../components/Footer";
import TopNav from "../components/TopNav";
import PortalPopup from '../components/PortalPopup';
import AuthModal from '../components/AuthModal';
import "./HomeScreen.css";

const HomeScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState('FLOWER');
  const [seeAll, setSeeAll] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const user = useSelector((state) => state.user); // Access the user state from the Redux store

  useEffect(() => {
    console.log('User info:', user); // Log user info to check if logged in
  }, [user]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        setIsLoading(true);
        const treezData = await fetchTreezProducts();
        
        const processedProducts = await Promise.all(treezData.data.product_list.map(async (product) => {
          const firebaseDetails = await getProductDetails(product.product_id);
          return {
            id: product.product_id,
            name: product.product_configurable_fields.name,
            price: product.pricing.price_sell,
            discountedPrice: product.pricing.discounted_price,
            category: product.category_type,
            classification: product.product_configurable_fields.classification,
            brand: product.product_configurable_fields.brand,
            sellableQuantity: product.sellable_quantity,
            imageUrl: firebaseDetails?.image_url || '/default-product-image.png',
            description: firebaseDetails?.description || 'No description available.'
          };
        }));

        setProducts(processedProducts);
        console.log(processedProducts)
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadProducts();
  }, []);

  const filteredProducts = products.filter(product => {
    return product.sellableQuantity > 0 && product.category === selectedProductType;
  });

  const dealProducts = products.filter(product => {
    if (!product.sellableQuantity > 0 || !product.price || !product.discountedPrice) return false;
    const discountPercentage = ((product.price - product.discountedPrice) / product.price) * 100;
    return discountPercentage >= 30;
  });

  const handleSeeAllClick = () => {
    setFadeOut(true);
    setTimeout(() => {
      setSeeAll(true);
      setFadeOut(false);
    }, 500); // Match the duration of the fade-out animation
  };

  const handleBackClick = () => {
    setFadeOut(true);
    setTimeout(() => {
      setSeeAll(false);
      setFadeOut(false);
      setFadeIn(true);
      setTimeout(() => setFadeIn(false), 500); // Match the duration of the fade-in animation
    }, 500); // Match the duration of the fade-out animation
  };

  const openAuthModal = useCallback(() => {
    setAuthModalOpen(true);
  }, []);

  const closeAuthModal = useCallback(() => {
    setAuthModalOpen(false);
  }, []);

  return (
    <div className="homescreen">
      <main className="home-page">
        <section className="frame-parent">
          {/* {!seeAll && <HomeHero className={fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''} />} */}
          <div className={`category-select-wrapper ${seeAll ? 'fade-up' : ''}`}>
            <div className="section-header" data-text="THE STASH">
              THE STASH
            </div>
            <CategorySelect 
              setSelectedProductType={setSelectedProductType}
              seeAll={seeAll}
              setSeeAll={setSeeAll}
              handleBackClick={handleBackClick}
              products={products}
            />
          </div>
          <img
            className="night-mode-1-traced"
            alt=""
            src="/nightmode-1-traced.svg"
          />
          <img
            className="cloud-1-traced1"
            loading="lazy"
            alt=""
            src="/cloud-1-traced.svg"
          />
          {isLoading ? 
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
            : 
            <>
              <div className={`product-gallery-wrapper ${seeAll ? 'see-all fade-up' : ''} ${fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''}`}>
                <ProductGallery 
                  products={filteredProducts} 
                  onSeeAllClick={handleSeeAllClick} 
                  seeAll={seeAll}
                  onLoginClick={openAuthModal}
                />
              </div>
              {!seeAll && (
                <>
                  <SpotlightBanner className={fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''} />
                  <div className="product-gallery-wrapper">
                    <div className="section-header" data-text="THE DEALS">
                      THE DEALS
                    </div>
                    <ProductGallery 
                      products={dealProducts}
                      onSeeAllClick={handleSeeAllClick} 
                      seeAll={false}
                      onLoginClick={openAuthModal}
                    />
                  </div>
                </>
              )}
            </>
          }
          {!seeAll && <Footer className={`footer ${fadeOut ? 'fade-out' : fadeIn ? 'fade-in' : ''}`} />}
        </section>
        <TopNav user={user} products={products} onLoginClick={openAuthModal} />
        {isAuthModalOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Centered"
            onOutsideClick={closeAuthModal}
          >
            <AuthModal onClose={closeAuthModal} />
          </PortalPopup>
        )}
      </main>
    </div>
  );
};

export default HomeScreen;