import * as actionTypes from './actionTypes';
import { collection, onSnapshot, addDoc, doc, updateDoc, query, getDoc, increment, getDocs, where, deleteDoc, Timestamp} from 'firebase/firestore';
import { db } from '../../firebase';

import { fetchUserCart } from '../../utils/FirebaseUtils';

const convertTimestamps = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach(key => {
    if (newObj[key] && typeof newObj[key] === 'object' && newObj[key].seconds) {
      newObj[key] = newObj[key].toDate().toISOString();
    } else if (typeof newObj[key] === 'object') {
      newObj[key] = convertTimestamps(newObj[key]);
    }
  });
  return newObj;
};

export const fetchCart = (userId) => async (dispatch) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const cartSnapshot = await getDocs(cartRef);
    const cartItems = cartSnapshot.docs.map(doc => ({
      ...doc.data(),
      cartItemId: doc.id // Store the Firebase document ID
    }));
    dispatch({
      type: actionTypes.SET_CART,
      payload: cartItems
    });
  } catch (error) {
    console.error("Error fetching cart:", error);
    dispatch({
      type: actionTypes.SET_CART,
      payload: []
    });
  }
};

export const fetchOrders = (userId) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_ORDERS_START });
  try {
    const ordersRef = collection(db, 'orders');
    const q = query(ordersRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const orders = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...convertTimestamps(doc.data())
    }));
    dispatch({ type: actionTypes.FETCH_ORDERS_SUCCESS, payload: orders });
  } catch (error) {
    console.error('Error fetching orders:', error);
    dispatch({ type: actionTypes.FETCH_ORDERS_FAILURE, payload: error.message });
  }
};

export const addProductToCart = (product) => async (dispatch, getState) => {
  const { user } = getState();
  const cartRef = collection(db, 'users', user.userId, 'cart');

  try {
    // Check if the product already exists in the cart
    const existingProductQuery = query(cartRef, where('id', '==', product.id));
    const existingProductSnapshot = await getDocs(existingProductQuery);

    if (!existingProductSnapshot.empty) {
      // If product exists, increment the quantity
      const existingDoc = existingProductSnapshot.docs[0];
      const existingQuantity = existingDoc.data().quantity || 0;
      const newQuantity = existingQuantity + 1;
      await updateDoc(doc(cartRef, existingDoc.id), { quantity: newQuantity });

      dispatch({
        type: actionTypes.UPDATE_CART_ITEM_QUANTITY,
        payload: { id: product.id, quantity: newQuantity },
      });
    } else {
      // If product doesn't exist, add it to the cart with quantity 1
      const newProduct = { 
        ...product, 
        quantity: 1,
        brand: product.brand || "Brand",
        name: product.name || "Product Name",
        category: product.category || "Category",
        classification: product.classification || "Classification",
      };
      await addDoc(cartRef, newProduct);

      dispatch({
        type: actionTypes.ADD_PRODUCT_TO_CART,
        payload: newProduct,
      });
    }
  } catch (error) {
    console.error('Error adding product to cart:', error);
    // Optionally dispatch an error action
  }
};

export const removeFromCart = (userId, productId) => async (dispatch) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const q = query(cartRef, where('id', '==', productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const cartItemDoc = querySnapshot.docs[0];
      await deleteDoc(cartItemDoc.ref);

      dispatch({
        type: actionTypes.REMOVE_PRODUCT_FROM_CART,
        payload: { id: productId },
      });
    } else {
      console.error('Cart item not found');
    }
  } catch (error) {
    console.error('Error removing item from cart:', error);
  }
};

export const createOrder = (orderData, ticketResponse) => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const cartItems = getState().order.cart;

    const itemsWithDetails = cartItems.map(item => ({
      brand: item.brand || "Brand",
      category: item.category || "Category",
      classification: item.classification || "Classification",
      imageUrl: item.imageUrl || "/default-product-image.png",
      name: item.name || "Product Name",
      price: parseFloat(item.price.toFixed(2)), // Ensure price is stored as a number with 2 decimal places
      productId: item.id,
      quantity: item.quantity
    }));

    const totalAmount = parseFloat(orderData.totalAmount.toFixed(2)); // Ensure total amount is stored as a number with 2 decimal places

    const firestoreOrderData = {
      userId: user.userId,
      orderStatus: "VERIFICATION_PENDING",
      orderTime: new Date().toISOString(),
      paymentStatus: "VERIFICATION_PENDING",
      items: itemsWithDetails.reduce((acc, item, index) => {
        acc[index.toString()] = item;
        return acc;
      }, {}),
      ticketData: {
        ...orderData.ticketData,
        totalAmount: totalAmount
      },
      treezOrderNumber: ticketResponse?.data?.order_number || '',
      treezOrderStatus: ticketResponse?.data?.order_status || "PREVIEW",
      treezPaymentStatus: ticketResponse?.data?.payment_status || "UNPAID",
      treezTicketId: ticketResponse?.data?.ticket_id || null
    };

    const docRef = await addDoc(collection(db, 'orders'), firestoreOrderData);

    dispatch({
      type: actionTypes.CREATE_ORDER,
      payload: {
        ...firestoreOrderData,
        id: docRef.id,
      },
    });

    return docRef.id;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const updateCartItemQuantity = (cartItemId, productId, newQuantity) => async (dispatch, getState) => {
  const { user } = getState();
  
  try {
    // First, query to find the correct cart item document
    const cartRef = collection(db, 'users', user.userId, 'cart');
    const q = query(cartRef, where('id', '==', productId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const cartItemDoc = querySnapshot.docs[0];
      await updateDoc(cartItemDoc.ref, { quantity: newQuantity });

      dispatch({
        type: actionTypes.UPDATE_CART_ITEM_QUANTITY,
        payload: { id: productId, cartItemId: cartItemDoc.id, quantity: newQuantity },
      });
    } else {
      console.error('Cart item not found');
    }
  } catch (error) {
    console.error('Error updating cart item quantity:', error);
  }
};

// Define other actionTypes as needed.
