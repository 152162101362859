import ItemInfo from "./ItemInfo";
import { useState } from "react";
import ProductImageBig from "./ProductImageBig";
import ItemDescription from "./ItemDescription";
import QuantityCounter from "./QuantityCounter";
import "./ItemDetailsModal.css";

const ItemDetailsModal = ({ product, onClose, currentPrice, originalPrice }) => {
  if (!product) return null;

  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };


  return (
    <div className="itemdetailsmodal">
      <section className="container-component-wrapper">
        <div className="container-component">
          <div className="frame-group">
            <ItemInfo product={product} />
            <div className="frame-wrapper">
              <div className="rectangle-parent">
                {/* <ProductImageBig /> */}
                <img
                  className="cbx-1-removebg-preview-1-icon"
                  loading="lazy"
                  alt=""
                  src={product.imageUrl}
                />
              </div>
            </div>
          </div>
          <div className="product-info-box" />
          <ItemDescription product={product}/>
          <div className="frame-container">
            <img
              className="frame-child"
              loading="lazy"
              alt=""
              src="/group-3.svg"
            />
            <div className="pickup-from-off-the-charts-v-wrapper">
              <div className="pickup-from-off">
                Pickup From The Woods - WeHo
              </div>
            </div>
          </div>
          <div className="centered-content">
            <div className="frame-div">
              <div className="minus-circle-icon-wrapper">
                <div className="minus-circle-icon">
                  <div className="add-circle-icon">${currentPrice}.00</div>
                  <div className="wrapper">
                    <div className="div">${originalPrice}.00</div>
                  </div>
                </div>
              </div>
              <QuantityCounter 
                inventoryCount={product.inventory_count}
                onQuantityChange={handleQuantityChange}
                productId={product.id}
              />
            </div>
            <div className="frame-parent1">
              <button className="buy-now-wrapper">
                <div className="buy-now">Buy Now</div>
              </button>
              <button className="add-to-stash-wrapper">
                <div className="add-to-stash">Add to Stash</div>
              </button>
            </div>
          </div>
        </div>
      </section>
      <img
        className="vuesaxlinearclose-circle-icon"
        loading="lazy"
        alt=""
        src="/vuesaxlinearclosecircle.svg"
        onClick={onClose}
      />
    </div>
  );
};

export default ItemDetailsModal;
