import { 
    getAuth, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    sendPasswordResetEmail, 
    signOut,
    onAuthStateChanged
  } from 'firebase/auth';
  // import { Alert } from 'react-native';
  import { app, auth, db, functions } from '../firebase';
  import { doc, getDoc, setDoc, getFirestore } from 'firebase/firestore';
  // import 'react-native-get-random-values';
  // import axios from 'axios';
  
  import { store, persistor} from '../redux/store';
  import { setUser } from '../redux/actions/userActions';
  import { createStripeCustomer, getPaymentMethods } from '../redux/actions/stripeActions';
  
  
  // const auth = getAuth(app);
  // const db = getFirestore(app);
  
  const AuthService = {
    // Sign up a new user with email and password
    signUp: async (email, password, navigation) => {
      console.log('Attempting to sign up:', email);
    
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
        const user = userCredential.user;
    
        if (user) {
          const userId = user.uid;
          console.log('User account created & signed in with UID:', userId);
          
          // Create Stripe customer
          try {
            await store.dispatch(createStripeCustomer(email, userId));
            console.log('Stripe customer creation triggered');
          } catch (stripeError) {
            console.error('Error creating Stripe customer:', stripeError);
            window.alert('Error', 'Failed to create Stripe customer.');
            return; // Exit the function if the Stripe customer creation fails
          }

          await AuthService.signIn(email, password);
        } else {
          // If user is not signed in, handle it accordingly
          console.error('User sign-in failed after sign up.');
          window.alert('Sign In Error', 'Failed to sign in after sign up.');
        }
      } catch (error) {
          console.error(error);
          let errorMessage = 'An error occurred during sign up.';
          if (error.code === 'auth/email-already-in-use') {
            errorMessage = 'That email address is already in use!';
          } else if (error.code === 'auth/invalid-email') {
            errorMessage = 'That email address is invalid!';
          }
          window.alert('Sign Up Error', errorMessage);
      }
    },
    
    // Sign in a user with email and password
    signIn: async (email, password) => {
  
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('User signed in!');
        console.log('User credential:', userCredential);
        const userId = userCredential.user.uid; // Get user ID from the authentication
        const userDoc = await getDoc(doc(db, "users", userId)); // Get user document from Firestore
        console.log('User document fetched:', userDoc);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log('User data fetched!', userData);
          if (userData.stripeCustomerId) {
            const stripeCustomerId = userData.stripeCustomerId;
            store.dispatch(getPaymentMethods(stripeCustomerId));
          } else {
            // Handle the case where stripeCustomerId doesn't exist yet
            console.log('Stripe customer ID not yet available');
          }

          const createdAt = userData.createdAt ? userData.createdAt.toDate().toISOString() : null;
          const lastUpdatedAt = userData.lastUpdatedAt ? userData.lastUpdatedAt.toDate().toISOString() : null;
          // Dispatch the setUser action to the Redux store
          store.dispatch(setUser({
            email: userData.email,
            userId: userData.userId,
            stripeCustomerId: userData.stripeCustomerId,
            role: userData.role,
            accountName: userData.accountName,
            legalName: userData.legalName,
            birthday: userData.birthday,
            phoneNumber: userData.phoneNumber,
            photoUrl: userData.photoUrl,
            createdAt: createdAt,
            lastUpdatedAt: lastUpdatedAt,
          }));
          console.log('User role:', userData.role);
          // switch (userData.role) {
          //   case 'admin':
          //     console.log('Navigating to AdminDashboard');
          //     // navigation.navigate('AdminDashboard');
          //     break;
          //   case 'store':
          //     navigation.navigate('StorePanelStack', { storeId: userData.userId });
          //     break;
          //   case 'user':
          //     navigation.navigate('Home');
          //     break;
          //   default:
          //     console.error('Invalid role or role not found:', userData.role);
          //     throw new Error('Invalid role or role not found');
          // }
  
          return userData;
        } else {
          console.log('No such user data!');
        }
  
      }  catch (error) {
        console.error(error);
        let errorMessage = 'An error occurred during sign in.';
        if (error.code === 'auth/invalid-email') {
          errorMessage = 'That email address is invalid!';
        } else if (error.code === 'auth/user-disabled') {
          errorMessage = 'User with this email has been disabled.';
        } else if (error.code === 'auth/user-not-found') {
          errorMessage = 'User with this email does not exist.';
        } else if (error.code === 'auth/wrong-password') {
          errorMessage = 'Incorrect password.';
        }
        window.alert('Sign In Error', errorMessage); // Display error message to the user
      }
  
      // After successful sign-in and fetching user data
      
    },
  
    // Send password reset email
    resetPassword: async (email) => {
  
      try {
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset email sent!');
        Alert.alert('Reset Password', 'Password reset email sent! Please check your inbox.'); // Show confirmation message
      } catch (error) {
        console.error(error);
        let errorMessage = 'An error occurred during password reset.';
        if (error.code === 'auth/invalid-email') {
          errorMessage = 'That email address is invalid!';
        } else if (error.code === 'auth/user-not-found') {
          errorMessage = 'User with this email does not exist.';
        }
        Alert.alert('Reset Password Error', errorMessage); // Display error message to the user
      }
    },
  
    logout: async () => {
      try {
        await signOut(auth);
        await persistor.purge();
      } catch (error) {
        console.error('Error signing out:', error);
        throw error; // Re-throw the error to be handled by the caller
      }
    },
  
    // ... other methods
  };
  
  export default AuthService;
  