import "./LocationFull.css";

const LocationFull = () => {
  return (
    <div className="frame-parent45">
      <div className="pickup-location-wrapper">
        <div className="pickup-location">Pickup Location</div>
      </div>
      <div className="frame-parent46">
        <div className="frame-wrapper16">
          <img
            className="frame-child13"
            loading="lazy"
            alt=""
            src="/group-32.svg"
          />
        </div>
        <div className="the-woods-container">
          <p className="the-woods-">The Woods - WeHo</p>
          <p className="main-st-los">8271 Santa Monica Blvd, West Hollywood, CA 90046</p>
        </div>
      </div>
    </div>
  );
};

export default LocationFull;
