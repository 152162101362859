import * as actionTypes from './actionTypes';

export const setUser = user => ({
    type: actionTypes.SET_USER,
    payload: user
  });

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};