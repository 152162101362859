import "./ShopNowButton.css";

const ShopNowButton = () => {
  return (
    <button className="shop-now-wrapper">
      <div className="shop-now">Coming Soon!</div>
    </button>
  );
};

export default ShopNowButton;
