import React from 'react';
import "./ConfirmButton.css";

const ConfirmButton = ({ onClick, disabled }) => {
  return (
    <button 
      className="confirm-purchase-wrapper" 
      onClick={onClick} 
      disabled={disabled}
    >
      <div className="confirm-purchase">Confirm Purchase</div>
    </button>
  );
};

export default ConfirmButton;
