import { useMemo } from "react";
import "./SeeAllButton.css";

const SeeAllButton = ({ frameButtonBorder, onClick }) => {
  const frameButtonStyle = useMemo(() => {
    return {
      border: frameButtonBorder,
    };
  }, [frameButtonBorder]);

  return (
    <button className="see-all-wrapper" style={frameButtonStyle} onClick={onClick}>
      <div className="see-all">See All</div>
    </button>
  );
};

export default SeeAllButton;
