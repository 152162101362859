import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

import LocationFull from "./LocationFull";
import ConfirmButton from "./ConfirmButton";
import CancelButton from "./CancelButton";
import QuantityCounter from "./QuantityCounter";
import VerificationForm from "./VerificationForm";
import ModalHeader from "./ModalHeader";
import ItemDetailsModal from "./ItemDetailsModal";
import OrderDetails from "./OrderDetails";
import PortalPopup from "./PortalPopup";

import { updateCartItemQuantity } from '../../redux/actions/orderActions';
import { previewTreezTicket, createTreezTicket } from '../../utils/TreezService';

import "./BuyNowModal.css";

const BuyNowModal = ({ onClose, product, currentPrice, originalPrice, onOpenItemDetails }) => {
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  // Check if product is undefined
  if (!product) {
    console.error('Product is undefined in BuyNowModal');
    return null;
  }

  console.log('user', user)

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
    dispatch(updateCartItemQuantity(null, product.id, newQuantity));
  };

  const totalPrice = (product.price * quantity).toFixed(2);

  const handleVerifyAccount = (formData) => {
    // For now, just log the form data
    console.log('[BuyNowModal] Verification form submitted:', formData);
    handleVerificationSuccess();
  };

  const handleVerificationSuccess = () => {
    setPurchaseStatus(true);
    setShowVerificationForm(false);
  };

  const handleConfirmPurchase = async () => {
    console.log("Confirming purchase for product:", product);
    setIsLoading(true);
    setError(null);
    
    try {
      const ticketData = {
        type: "PICKUP", // or "DELIVERY" based on your app's logic
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            location_name: "STASHPANDA",
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
      };
      
      const result = await createTreezTicket(ticketData);
      console.log("[BuyNowModal] Created Treez ticket:", result);
      
      if (result.resultCode === 'SUCCESS') {
        // Calculate total amount
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        // Create an object with all fields, filtering out undefined values
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: serverTimestamp(),
          totalAmount: totalAmount,
          items: [
            {
              productId: product.id,
              quantity: quantity,
              price: product.price ,
            }
          ],
          paymentStatus: "PENDING",
          ticketData: ticketData,
        };

        // Remove any fields with undefined values
        Object.keys(orderData).forEach(key => {
          if (orderData[key] === undefined) {
            delete orderData[key];
          }
        });

        console.log("[BuyNowModal] Creating order:", orderData);

        // Now add the document with cleaned data
        await addDoc(collection(db, 'orders'), orderData);
        
        console.log("[BuyNowModal] Order created successfully");
        // Optionally, you can dispatch an action or update the UI here
        
        onClose();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process your purchase. Please try again.");
      setIsLoading(false);
    }
  };

  const handlePreviewConfirmPurchase = async () => {
    console.log("Confirming purchase for product:", product);
    setIsLoading(true);
    setError(null);
    
    try {
      const ticketData = {
        type: "PICKUP", // or "DELIVERY" based on your app's logic
        order_source: "ECOMMERCE",
        order_status: "AWAITING_PROCESSING",
        items: [
          {
            location_name: "STASHPANDA",
            size_id: product.id,
            quantity: quantity,
          }
        ],
        revenue_source: 'STASHPANDA',
        ticket_note: 
          `Customer: ${user.accountName}, 
          Email: ${user.email}, 
          ID Type: ${user.idType || 'Driver License'},
          DL State: ${user.licenseState || 'N/A'},
          ID Number: ${user.idNumber || 'N/A'},
          Phone: ${user.phoneNumber || 'N/A'}
          `,
      };
      
      const previewResult = await previewTreezTicket(ticketData);
      console.log("[BuyNowModal] Previewing Treez ticket:", previewResult);
      
      if (previewResult.resultCode === 'SUCCESS') {
        // Calculate total amount
        const totalAmount = parseFloat((product.price * quantity).toFixed(2));
        
        // Create an object with all fields, filtering out undefined values
        const orderData = {
          userId: user.userId,
          orderStatus: "AWAITING_PROCESSING",
          orderTime: serverTimestamp(),
          totalAmount: totalAmount,
          items: [
            {
              productId: product.id,
              quantity: quantity,
              price: product.price ,
            }
          ],
          paymentStatus: "AWAITING_PAYMENT",
          ticketData: ticketData,
        };

        // Remove any fields with undefined values
        Object.keys(orderData).forEach(key => {
          if (orderData[key] === undefined) {
            delete orderData[key];
          }
        });

        console.log("[BuyNowModal] Creating order:", orderData);

        // Now add the document with cleaned data
        await addDoc(collection(db, 'orders'), orderData);
        
        console.log("[BuyNowModal] Order created successfully");
        // Optionally, you can dispatch an action or update the UI here
        
        onClose();
      } else {
        setError("Ticket creation was not successful. Please try again.");
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error("[BuyNowModal] Error handling purchase:", error);
      setError("Failed to process your purchase. Please try again.");
      setIsLoading(false);
    }
  };

  const lineageLabel = useMemo(() => {
    if (product.classification) {
      switch (product.classification.toLowerCase()) {
        case 'indica':
          return { text: 'Indica', backgroundColor: 'var(--color-deepskyblue-100)' };
        case 'sativa':
          return { text: 'Sativa', backgroundColor: 'var(--color-sativa-yellow)' }; 
        case 'cbd':
          return { text: 'CBD', backgroundColor: 'var(--color-deeppink-300)' };
        case 'hybrid':
          return { text: 'Hybrid', backgroundColor: 'var(--color-hybrid-green)'};
        default:
          return null;
      }
    }
    return null;
  }, [product.classification]);

  return (
    <>
      <div className="buy-now-modal">
        <section className="buy-now-modal-content">
          <div className="buy-now-modal-header">
            <ModalHeader 
              onClose={onClose} 
              title={showVerificationForm ? "Verify Account" : "Complete Purchase"}
            />
          </div>

          {showVerificationForm ? (
            <VerificationForm 
              onSubmit={handleVerifyAccount} 
              onVerificationSuccess={handleVerificationSuccess} 
            />
          ) : (
            <>
              <div className="product-info-container">
                <div className="buy-now-image-container">
                  {product.discountedPrice && product.price && (
                    <div className="discount-label">
                      {Math.round(((product.price - product.discountedPrice) / product.price) * 100)}% OFF
                    </div>
                  )}
                  {lineageLabel && (
                    <div 
                      className="lineage-label" 
                      style={{ backgroundColor: lineageLabel.backgroundColor }}
                    >
                      {lineageLabel.text}
                    </div>
                  )}
                  <img
                    className="expand-icon"
                    src="/frame-1597890375.svg"
                    alt="Expand"
                    onClick={() => {
                      onClose();
                      onOpenItemDetails && onOpenItemDetails(product);
                    }}
                  />
                  <img
                    className="product-image"
                    loading="lazy"
                    alt="Product"
                    src={product.imageUrl || '/default-product-image.png'}
                  />
                </div>
                <div className="item-info-container">
                  <div className="product-info-text">
                    <div className="product-name">{product.name}</div>
                    <div className="product-brand">{product.brand}</div>
                    <div className="product-lineage">{product.lineage}</div>
                    <div className="price-container">
                      <div className="current-price">${Number(product.discountedPrice).toFixed(2)}</div>
                      <div className="original-price">${Number(product.price).toFixed(2)}</div>
                    </div>
                  </div>
                  <QuantityCounter 
                    inventoryCount={product.sellableQuantity} 
                    onQuantityChange={handleQuantityChange}
                    initialQuantity={quantity}
                    isBuyNow={true}
                  />  
                </div>
                
                <img
                  className="info-icon"
                  loading="lazy"
                  alt="Information"
                  src="/informationbutton-1-traced.svg"
                />
              </div>
              <div className="divider" />
              <div className="details-container">
                <LocationFull />
                <div className="divider" />
                <OrderDetails />
              </div>
              <div className="button-container">
                <div className="button-wrapper">
                  <ConfirmButton 
                    onClick={() => purchaseStatus ? handleConfirmPurchase() : setShowVerificationForm(true)}
                    text={isLoading ? "Processing..." : purchaseStatus ? "Confirm Purchase" : "Verify Account"}
                    disabled={isLoading}
                  />
                </div>
              </div>
              {isLoading && <p>Loading...</p>}
              {error && <p className="error">{error}</p>}
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default BuyNowModal;
