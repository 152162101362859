import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import SeeAllButton from "./SeeAllButton";
import "./ProductGallery.css";

const ProductGallery = ({ products, onSeeAllClick, seeAll, onLoginClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const imagePromises = products.map(product => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = resolve; // Resolve even on error
        img.src = product.imageUrl;
      });
    });

    Promise.all(imagePromises).then(() => {
      setIsLoading(false);
    });
  }, [products]);

  return (
    <div className="frame-wrapper6">
      <div className="frame-parent15">
        <div className={`product-gallery ${isLoading ? 'loading' : ''}`}>
          {products.map((product) => (
            <ProductCard 
              key={product.id}
              {...product}
              cartItemId={product.cartItemId}
              isLoading={isLoading}
              onLoginClick={onLoginClick}
            />
          ))}
        </div>
      </div>
      {!seeAll && (
        <div className="centered-button">
          <SeeAllButton onClick={onSeeAllClick} />
        </div>
      )}
    </div>
  );
};

export default ProductGallery;
