import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const QRTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const source = urlParams.get('source');
    if (source && source.startsWith('qr_code_')) {
      window.gtag('event', 'qr_code_scan', {
        'event_category': 'QR Code',
        'event_label': source
      });
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default QRTracker;
