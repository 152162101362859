import "./ItemInfo.css";

const ItemInfo = ({ product }) => {

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="frame-parent38">
      <div className="white-walker-og-35g-parent">
        <h1 className="white-walker-og2">{product.name}</h1>
        <div className="indica">{capitalize(product.lineage)}</div>
        <h2 className="cbx2">{product.brand}</h2>
        <div className="thc-26-30-parent">
          <div className="thc-26-container">
            <span className="thc">{`THC : `}</span>
            <b className="b">26 - 30%</b>
          </div>
          <div className="removebg-preview-box" />
          <div className="relaxing-joyful-tranquil">
            Relaxing, Joyful, Tranquil
          </div>
        </div>
      </div>
      <div className="category-info">
        <div className="category">Category: </div>
        <div className="flower1">{capitalize(product.kind)}</div>
      </div>
    </div>
  );
};

export default ItemInfo;
