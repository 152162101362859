import React from "react";
import "./ProductImageSmall.css";

const ProductImageSmall = ({ imageUrl }) => {
  return (
    <div className="frame-child12">
      {imageUrl && <img src={imageUrl} alt="Product" className="product-image" />}
    </div>
  );
};

export default ProductImageSmall;
