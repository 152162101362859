import React, { useState, useEffect } from 'react';
import StashModal from './StashModal';
import './TransitionModal.css';

const TransitionModal = ({ onClose, products, initialMode = 'stash', openAccountModal }) => {
  const [mode, setMode] = useState(initialMode);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setMode(initialMode);
  }, [initialMode]);

  const handlePurchaseComplete = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setMode('order');
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <div className="transition-modal-container">
      <div className={`transition-modal ${isTransitioning ? 'transitioning' : ''}`}>
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <StashModal
              onClose={onClose}
              products={products}
              onPurchaseComplete={handlePurchaseComplete}
              initialMode={mode}
              openAccountModal={openAccountModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransitionModal;