import "./ItemDescription.css";

const ItemDescription = ({product}) => {
  return (
    <div className="this-knockout-west">
      {product.description}
      </div>
  );
};

export default ItemDescription;
