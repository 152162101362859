import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { IonIcon } from '@ionic/react';
import { personCircleOutline, pencilOutline, checkmarkCircleOutline, cardOutline, alertCircleOutline } from 'ionicons/icons';

import { updateUserEmail, resetUserPassword } from '../../utils/FirebaseUtils';
import AuthService from "../../utils/AuthService";
import { clearUser } from "../../redux/actions/userActions";

import ModalHeader from "./ModalHeader";
import "./AccountModal.css";
import LoginButton from './LoginButton';
import VerificationForm from './VerificationForm';

const AccountModal = ({ onClose, user }) => {
  const [formData, setFormData] = useState({
    email: user.email || "",
    purchaseStatus: user.purchaseStatus || false
  });
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [status, setStatus] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      email: user.email || "",
      purchaseStatus: user.purchaseStatus || false
    }));
  }, [user]);

  useEffect(() => {
    if (formData.purchaseStatus) {
      setStatus("Your account is all set.");
    } else {
      setStatus("Update account to make a purchase.");
    }
  }, [formData.purchaseStatus]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEmailEdit = async () => {
    if (isEditingEmail) {
      await updateUserEmail(user.userId, formData.email);
    }
    setIsEditingEmail(!isEditingEmail);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleResetPassword = async () => {
    if (isResettingPassword) {
      if (passwords.newPassword === passwords.confirmPassword) {
        await resetUserPassword(formData.email, passwords.newPassword);
        alert("Password reset successfully!");
        setIsResettingPassword(false);
      } else {
        alert("Passwords do not match!");
      }
    } else {
      setIsResettingPassword(true);
    }
  };

  const handleLogout = async () => {
    try {
      await AuthService.logout();
      dispatch(clearUser());
      onClose();
    } catch (error) {
      console.error('Logout failed', error);
      window.alert('Logout Failed', error.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Not set";
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "Not set";
      return date.toLocaleDateString();
    } catch (error) {
      console.error('Error formatting date:', error);
      return "Not set";
    }
  };

  return (
    <div className="account-modal">
      <div className="modal-header-wrapper">
        <ModalHeader title="Your Account" onClose={onClose} />
      </div>
      <hr className="divider" />
      <div className="account-modal-content">
        {!formData.purchaseStatus ? (
          <VerificationForm onVerificationSuccess={() => setFormData(prev => ({ ...prev, purchaseStatus: true }))} />
        ) : (
          <>
            <div className="greeting-section">
              <h2 className="greeting">
                Hi, {user.accountName ? user.accountName : "Stash Panda"}!
              </h2>
            </div>
            <div className="profile-section">
              <div className="profile-left-column">
                <div className="profile-icon-wrapper">
                  <IonIcon icon={personCircleOutline} className="profile-icon" />
                </div>
                <div className="profile-item email-item">
                  {isEditingEmail ? (
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="profile-input"
                    />
                  ) : (
                    <span className="profile-value">{formData.email}</span>
                  )}
                  <IonIcon icon={isEditingEmail ? checkmarkCircleOutline : pencilOutline} className="edit-icon" onClick={handleEmailEdit} />
                  <div className="login-button-wrapper">
                    <LoginButton onClick={handleLogout} user={user} />
                  </div>
                </div>
                {isResettingPassword ? (
                  <div className="password-reset-section">
                    <input
                      type="password"
                      name="newPassword"
                      value={passwords.newPassword}
                      onChange={handlePasswordChange}
                      className="profile-input"
                      placeholder="New Password"
                    />
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwords.confirmPassword}
                      onChange={handlePasswordChange}
                      className="profile-input"
                      placeholder="Confirm Password"
                    />
                    <button onClick={handleResetPassword} className="reset-password-button">Submit</button>
                  </div>
                ) : (
                  <button onClick={handleResetPassword} className="reset-password-button">Reset Password</button>
                )}
              </div>
              <div className="profile-right-column">
                <div className="profile-content-wrapper">
                  <div className="profile-item">
                    <span className="profile-label">Full Name:</span>
                    <span className="profile-value">{user.accountName || "Not set"}</span>
                  </div>          
                  <div className="profile-item">
                    <span className="profile-label">Birthday:</span>
                    <span className="profile-value">{formatDate(user.birthday)}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">Phone Number:</span>
                    <span className="profile-value">{user.phoneNumber || "Not set"}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">ID Type:</span>
                    <span className="profile-value">{user.idType || "Not set"}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">ID State:</span>
                    <span className="profile-value">{user.licenseState || "Not set"}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-label">ID Number:</span>
                    <span className="profile-value">{user.idNumber || "Not set"}</span>
                  </div>
                  <div className="profile-item" style={{ display: 'flex', justifyContent: 'center' }}>
                    <button 
                      className="edit-info-button" 
                      onClick={() => setFormData(prev => ({ ...prev, purchaseStatus: false }))}
                      style={{ 
                        width: '300px',
                        backgroundColor: '#1E3A8A',
                        color: 'white',
                        padding: '12px 24px',
                        border: '1px solid white',
                        borderRadius: '100px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '16px',
                        fontFamily: 'Outfit, sans-serif',
                        letterSpacing: '0.02em',
                        lineHeight: '150%',
                        boxShadow: '4px 8px 30px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      Edit Information
                    </button>
                  </div>
                  <div className={`status ${formData.purchaseStatus ? "status-ready" : "status-update"}`}>
                    <IonIcon icon={formData.purchaseStatus ? checkmarkCircleOutline : alertCircleOutline} className="status-icon" />
                    <span> {status}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr className="divider" />
            <div className="profile-section">
              <div className="profile-left-column">
                <div className="payment-icon-wrapper">
                  <IonIcon icon={cardOutline} className="payment-icon" />
                </div>
              </div>
              <div className="profile-right-column">
                <div className="profile-content-wrapper">
                  <span className="payment-coming-soon">Payments coming soon</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountModal;
