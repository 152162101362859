import * as actionTypes from '../actions/actionTypes';

const initialState = {
  cart: [],
  orders: [],
  paymentMethods: [],
  defaultPaymentMethod: null,
  // ... other state properties if needed
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CART:
      return {
        ...state,
        cart: action.payload
      };
    case actionTypes.ADD_PRODUCT_TO_CART:
      const existingProductIndex = state.cart.findIndex(item => item.id === action.payload.id);
      if (existingProductIndex !== -1) {
        // Update existing product quantity
        return {
          ...state,
          cart: state.cart.map((item, index) => 
            index === existingProductIndex
              ? { ...item, quantity: item.quantity + action.payload.quantity }
              : item
          )
        };
      } else {
        // Add new product to cart
        return {
          ...state,
          cart: [...state.cart, action.payload]
        };
      }
    case actionTypes.REMOVE_PRODUCT_FROM_CART:
      // Remove the product from the cart array
      return {
        ...state,
        cart: state.cart.filter(item => item.id !== action.payload.id),
      };
    case actionTypes.CREATE_ORDER_SUCCESS:
      // Add the new order to the orders array
      return {
        ...state,
        orders: [...state.orders, action.payload],
      };
    case actionTypes.CREATE_ORDER_FAIL:
      // Handle order creation failure (e.g., show an error message)
      return {
        ...state,
        // Possibly update state to reflect that order creation failed
      };
    case actionTypes.CREATE_ORDER:
      return {
        ...state,
        orders: [...state.orders, action.payload],
      };
    // Handle other actions for payment methods here...
    case actionTypes.UPDATE_CART_ITEM_QUANTITY:
      return {
        ...state,
        cart: state.cart.map(item => 
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        )
      };
    case actionTypes.FETCH_ORDERS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case actionTypes.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default orderReducer;
