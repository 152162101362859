import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="frame-footer">
        <div className="frame-wrapper9">
          <div className="frame-parent32">
            <div className="frame-parent33">
              <div className="big-logo-traced-parent">
                <img
                  className="big-logo-traced1"
                  alt=""
                  src="/big-logo-traced-1.svg"
                />
                <h2 className="stash-pandas1">Stash Pandas</h2>
              </div>
              <div className="lorem-ipsum-is">{`Waste not, smoke a lot`}</div>
            </div>
            {/* <div className="frame-parent34">
              <div className="email-address-wrapper">
                <div className="email-address">Email Address</div>
              </div>
              <div className="arrow-right-wrapper">
                <img
                  className="arrow-right-icon"
                  loading="lazy"
                  alt=""
                  src="/arrowright.svg"
                />
              </div>
            </div>
            <div className="frame-parent35">
              <button className="union-wrapper">
                <img
                  className="union-icon"
                  loading="lazy"
                  alt=""
                  src="/union.svg"
                />
              </button>
              <button className="group-wrapper">
                <img
                  className="group-icon"
                  loading="lazy"
                  alt=""
                  src="/group.svg"
                />
              </button>
              <button className="vector-container">
                <img
                  className="vector-icon"
                  loading="lazy"
                  alt=""
                  src="/vector.svg"
                />
              </button>
              <button className="instagram-1-1-wrapper">
                <img
                  className="instagram-1-1"
                  loading="lazy"
                  alt=""
                  src="/instagram-1-1.svg"
                />
              </button>
              <button className="fi-5968958-wrapper">
                <img
                  className="fi-5968958-icon"
                  loading="lazy"
                  alt=""
                  src="/fi-5968958.svg"
                />
              </button>
            </div> */}
          </div>
        </div>
        {/* <div className="frame-parent36">
          <div className="lorem-ipsum-parent">
            <div className="lorem-ipsum">Lorem Ipsum</div>
            <div className="lorem-ipsum-group">
              <div className="lorem-ipsum1">Lorem Ipsum</div>
              <div className="lorem-ipsum2">Lorem Ipsum</div>
              <div className="lorem-ipsum3">Lorem Ipsum</div>
            </div>
          </div>
          <div className="lorem-ipsum-container">
            <div className="lorem-ipsum4">Lorem Ipsum</div>
            <div className="lorem-ipsum-parent1">
              <div className="lorem-ipsum5">Lorem Ipsum</div>
              <div className="lorem-ipsum6">Lorem Ipsum</div>
              <div className="lorem-ipsum7">Lorem Ipsum</div>
            </div>
          </div>
          <div className="function-caller">
            <div className="lorem-ipsum8">Lorem Ipsum</div>
            <div className="lorem-ipsum-wrapper">
              <div className="lorem-ipsum9">Lorem Ipsum</div>
            </div>
          </div>
          <div className="lorem-ipsum-parent2">
            <div className="lorem-ipsum10">Lorem Ipsum</div>
            <div className="lorem-ipsum-parent3">
              <div className="lorem-ipsum11">Lorem Ipsum</div>
              <div className="lorem-ipsum12">Lorem Ipsum</div>
              <div className="lorem-ipsum13">Lorem Ipsum</div>
            </div>
          </div>
        </div> */}
        <div className="footer-background">
          <img
            className="stars-1-traced"
            loading="lazy"
            alt=""
            src="/stars-1-traced.svg"
            style={{ position: 'absolute', top: '10px', right: '10px', zIndex: -1 }}
          />
          <img
            className="footer-cloud-1-traced"
            alt=""
            src="/cloud-1-traced-1.svg"
          />
        </div>
        {/* <div className="copyright-2024" style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', padding: '15px' }}>
          Copyright © 2024, Stash Pandas
        </div> */}
      </footer>
    </div>
  );
};

export default Footer;
