import { useState } from "react";
import "./Input.css";

const Input = ({ placeholder, type = "text", value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  const getImageSrc = (placeholder) => {
    if (placeholder === "Search Here") {
      return "/vuesaxlinearsearchnormal.svg";
    }
    // Add more conditions here if needed for different placeholders
    return "";
  };

  const imageSrc = getImageSrc(placeholder);

  return (
    <div className="vuesaxlinearsearch-normal-parent">
      {imageSrc && (
        <img
          className="vuesaxlinearsearch-normal-icon"
          loading="lazy"
          alt=""
          src={imageSrc}
        />
      )}
      <input
        className="input-field"
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {!isFocused && !value && <div className="search-here">{placeholder}</div>}
    </div>
  );
};

export default Input;