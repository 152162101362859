import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export const fetchTreezProducts = async () => {
  const getTreezProducts = httpsCallable(functions, 'getTreezProducts');
  try {
    const result = await getTreezProducts();
    console.log('Treez products:', result.data);
    return result.data;
  } catch (error) {
    console.error("Error fetching Treez products:", error);
    throw error;
  }
};

export const previewTreezTicket = async (ticketData) => {
  const previewTicket = httpsCallable(functions, 'previewTreezTicket');
  try {
    const result = await previewTicket(ticketData);
    console.log("Treez Preview Ticket Response:", result.data);
    return result.data;
  } catch (error) {
    console.error("Error previewing Treez ticket:", error);
    if (error.details) {
      console.error("Error details:", error.details);
    }
    if (error.code) {
      console.error("Error code:", error.code);
    }
    throw error;
  }
};

export const createTreezTicket = async (ticketData) => {
  const createTicket = httpsCallable(functions, 'createTreezTicket');
  try {
    const result = await createTicket(ticketData);
    console.log("Treez Create Ticket Response:", result.data);
    return result.data;
  } catch (error) {
    console.error("Error creating Treez ticket:", error);
    if (error.details) {
      console.error("Error details:", error.details);
    }
    if (error.code) {
      console.error("Error code:", error.code);
    }
    throw error;
  }
};

export const listTreezProducts = async ({ dispensary, productType, pageNum, pageSize, customerType }) => {
  const callListTreezProducts = httpsCallable(functions, 'listTreezProducts');

  try {
    const result = await callListTreezProducts({
      dispensary,
      productType,
      pageNum,
      pageSize,
      customerType
    });
    console.log(result.data); // Log the product list
    return result.data; // Return the product list for further processing
  } catch (error) {
    console.error('Error listing Treez products:', error);
    throw error; // Rethrow or handle as needed
  }
};

export const fetchTreezInventory = async () => {
  const getTreezInventory = httpsCallable(functions, 'getTreezInventory');
  
  try {
    console.log("fetching inventory")
    const result = await getTreezInventory(); // Assuming no parameters are needed, or add them as required
    console.log('after fetch')
    console.log("Inventory Data:", result.data); // Log the inventory data
    return result.data; // Return the inventory data for further processing
  } catch (error) {
    console.error("Error calling getTreezInventory:", error);
    throw error; // Rethrow or handle as needed
  }
};

export const fetchTicketData = async () => {
  const getTicketData = httpsCallable(functions, 'getTicketData');
  try {
    const result = await getTicketData();
    console.log("Ticket Data:", result.data);
    return result.data;
  } catch (error) {
    console.error("Error calling getTicketData:", error);
    throw error;
  }
};

export const fetchIHeartJaneProducts = async () => {
  const getIHeartJaneProducts = httpsCallable(functions, 'getIHeartJaneMenuProducts');
  console.log('in jane')
  try {
    console.log('in try')
    const result = await getIHeartJaneProducts();
    console.log('after call')
    console.log("IHeartJane Products:", result); // Log the response
    return result.data;
  } catch (error) {
    console.error("Error calling getIHeartJaneMenuProducts:", error);
    throw error;
  }
};