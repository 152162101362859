import { httpsCallable } from 'firebase/functions';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  setDoc,
  deleteDoc,
  increment,
  onSnapshot
} from 'firebase/firestore';

import { db, functions, storage } from '../firebase';
import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";
import { store } from '../redux/store';
import { setUser } from '../redux/actions/userActions';

export const updateUserInfo = async (userId, userInfo) => {
  try {
    const updateUserInfoFunction = httpsCallable(functions, 'updateUserInfo');
    const result = await updateUserInfoFunction({ userId, ...userInfo });
    
    // After successful update, get the latest user data
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      store.dispatch(setUser({ userId, ...userData }));
    }
    
    return result.data;
  } catch (error) {
    console.error('Error updating user info:', error);
    throw new Error(error.message);
  }
};

export const updateUserEmail = async (userId, newEmail) => {
  try {
    const updateUserEmailFunction = httpsCallable(functions, 'updateUserEmail');
    const result = await updateUserEmailFunction({ userId, newEmail });
    
    // After successful update, get the latest user data
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      store.dispatch(setUser({ userId, ...userData }));
    }
    
    return result.data;
  } catch (error) {
    console.error('Error updating user email:', error);
    throw new Error(error.message);
  }
};

export const resetUserPassword = async (email) => {
  try {
    const resetUserPasswordFunction = httpsCallable(functions, 'resetUserPassword');
    const result = await resetUserPasswordFunction({ email });
    return result.data;
  } catch (error) {
    console.error('Error resetting user password:', error);
    throw new Error(error.message);
  }
};

// Fetch users
export const getUsers = async () => {
  try {
    const users = [];
    const snapshot = await getDocs(collection(db, 'users'));
    snapshot.forEach(doc => {
      users.push({ id: doc.id, ...doc.data() });
    });
    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const checkRole = async (userId) => {
  try {
    console.log('functions:', functions); // Debug: Log the 'functions' object
    const checkRoleFunction = httpsCallable(functions, 'checkRole');
    const result = await checkRoleFunction({ userId });
    console.log('result', result); // Debug: Log the result
    return result.data;
  } catch (error) {
    console.error('Error in checkRole:', error); // Debug: Log the error
    throw new Error(error.message);
  }
};

export const addUserRole = async (uid, role) => {
  try {
    const addUserRoleFunction = functions.httpsCallable('addUserRole');
    const result = await addUserRoleFunction({ uid, role });
    return result.data; // { message: "Role admin set for user target-user-uid" }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getStores = async () => {
  try {
    const stores = [];
    const snapshot = await getDocs(collection(db, 'stores'));
    snapshot.forEach(doc => {
      stores.push({ id: doc.id, ...doc.data() });
    });
    return stores;
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

// Update store details
export const updateStoreDetails = async (storeId, storeDetails) => {
  try {
    const storeRef = doc(db, 'stores', storeId);
    await updateDoc(storeRef, storeDetails);
  } catch (error) {
    console.error('Error updating store details:', error);
    throw error;
  }
};

export const getProductDetails = async (productId) => {
  try {
    const docRef = doc(db, 'productDetails', productId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      let imageUrl = '/default-product-image.png';

      if (data.image_url && data.image_url.startsWith('gs://')) {
        try {
          const imageRef = ref(getStorage(), data.image_url);
          imageUrl = await getDownloadURL(imageRef);
        } catch (imageError) {
          console.error('Error fetching image URL:', imageError);
        }
      } else if (data.image_url) {
        imageUrl = data.image_url;
      }

      return {
        ...data,
        imageUrl
      };
    } else {
      console.log('No product details found for ID:', productId);
      return null;
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
    return null;
  }
};

export const getInventory = async (storeId) => {
  try {
    const inventory = [];
    const snapshot = await getDocs(collection(db, `stores/${storeId}/inventory`));
    snapshot.forEach(doc => {
      inventory.push({ id: doc.id, ...doc.data() });
    });
    return inventory;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

export const getProducts = async () => {
  try {
    const stores = [];
    const allProducts = [];
    // Fetch all stores
    const storesSnapshot = await getDocs(collection(db, 'stores'));
    storesSnapshot.forEach(doc => {
      stores.push({ id: doc.id, ...doc.data() });
    });

    // Fetch products for each store
    for (const store of stores) {
      const inventorySnapshot = await getDocs(collection(db, `stores/${store.id}/inventory`));
      inventorySnapshot.forEach(doc => {
        allProducts.push({ storeId: store.id, ...doc.data() });
      });
    }

    return allProducts;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const updateStore = async (storeId, updatedData) => {
  try {
    const storeRef = doc(db, 'stores', storeId);
    await updateDoc(storeRef, updatedData);
  } catch (error) {
    console.error('Error updating store:', error);
    throw error;
  }
};

// Function to fetch store details
export const getStoreDetails = async (storeId) => {
  const storeRef = doc(db, 'stores', storeId);
  const storeSnapshot = await getDoc(storeRef);
  return storeSnapshot.exists() ? storeSnapshot.data() : null;
};

// Update inventory item
export const updateInventory = async (storeId, itemId, updatedData) => {
  try {
    const inventoryItemRef = doc(db, `stores/${storeId}/inventory`, itemId);
    await updateDoc(inventoryItemRef, updatedData);
  } catch (error) {
    console.error('Error updating inventory item:', error);
    throw error;
  }
};

// Function to mark an inventory item as out of stock
export const removeInventoryItem = async (storeId, itemId) => {
  const itemRef = doc(db, `stores/${storeId}/inventory`, itemId);
  await updateDoc(itemRef, { quantity: 0 });
};

// Function to add a new inventory item
export const addInventoryItem = async (storeId, itemDetails) => {
  const inventoryRef = collection(db, `stores/${storeId}/inventory`);
  await addDoc(inventoryRef, {
      ...itemDetails,
      storeId: storeId,
  });
};

// Function to fetch a single inventory item's details
export const getInventoryItem = async (storeId, itemId) => {
  const itemRef = doc(db, `stores/${storeId}/inventory`, itemId);
  const itemSnapshot = await getDoc(itemRef);
  return itemSnapshot.exists() ? itemSnapshot.data() : null;
};

export const getDealOfTheDay = async () => {
  const dealQuery = query(collection(db, 'products'), where('dealOfTheDay', '==', true));
  const dealSnapshot = await getDocs(dealQuery);
  const deal = dealSnapshot.empty ? null : { id: dealSnapshot.docs[0].id, ...dealSnapshot.docs[0].data() };
  return deal;
};

export const updateUserRole = async (userId, role) => {
  try {
    const updateUserRoleFunction = httpsCallable(functions, 'updateUserRole');
    const result = await updateUserRoleFunction({ userId, role });
    return result.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addProductToUserCart = async (userId, product) => {
  try {
    if (!userId || !product.id) {
        throw new Error("Missing userID or productID");
    }
    const userCartRef = doc(db, `users/${userId}/cart/${product.id}`);
    await setDoc(userCartRef, {
        ...product,
        quantity: 1 // Adjust this as necessary
    });
    return true; // Indicate success
  } catch (error) {
      console.error('Error adding product to cart:', error);
      throw error; // Re-throw to handle it appropriately
  }
};

export const fetchUserCart = async (userId) => {
  try {
    const cartRef = collection(db, 'users', userId, 'cart');
    const cartSnapshot = await getDocs(cartRef);
    const cartItems = cartSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return cartItems;
  } catch (error) {
    console.error("Error fetching user cart:", error);
    return [];
  }
};

export const checkoutUser = async (userId) => {
  if (!userId) throw new Error("User ID is required for checkout.");

  const cartRef = collection(db, `users/${userId}/cart`);
  const cartSnapshot = await getDocs(cartRef);
  const cartItems = cartSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  // Create order object
  const order = {
    userId,
    date: new Date(),
    items: cartItems,
    totalAmount: cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    // Add other fields as needed, like user details, shipping address, etc.
  };

  // Add order to 'orders' collection
  await addDoc(collection(db, 'orders'), order);

  // Update inventory for each product
  for (const item of cartItems) {
    const productRef = doc(db, `stores/${item.storeId}/inventory`, item.id);
    await updateDoc(productRef, { quantity: increment(-item.quantity) }); // decrement item quantity from inventory
  }

  // Clear user's cart after checkout
  for (const item of cartItems) {
    await deleteDoc(doc(db, `users/${userId}/cart`, item.id));
  }

  return order; // return the order for confirmation or further processing
};

export const createBuyNowOrder = async (userId, product, quantity) => {
  if (!userId || !product.id) throw new Error("Missing user ID or product details.");

  // Create the order object
  const order = {
    userId,
    date: new Date(),
    items: [{ ...product, quantity }],
    totalAmount: product.price * quantity,
    status: 'pending' // You can set the initial order status as required
  };

  // Add order to 'orders' collection
  const orderRef = await addDoc(collection(db, 'orders'), order);

  // Update inventory for the product
  const productRef = doc(db, `stores/${product.storeId}/inventory`, product.id);
  await updateDoc(productRef, { quantity: increment(-quantity) });

  // Return order info, might be useful for confirmation message or further processing
  return { ...order, id: orderRef.id };
};

export const getPreviouslyOrderedInStockProducts = async (userId) => {
  try {
    let productsMap = {}; // Use an object to store unique products

    // Fetch User's Orders
    const ordersQuery = query(collection(db, 'orders'), where('userId', '==', userId));
    const ordersSnapshot = await getDocs(ordersQuery);

    for (const orderDoc of ordersSnapshot.docs) {
      const order = orderDoc.data(); // Access the order data

      for (const item of order.items) {
        // Check In-Stock Status
        const inventoryItemRef = doc(db, `stores/${item.storeId}/inventory`, item.id);
        const inventoryItemSnapshot = await getDoc(inventoryItemRef);

        if (inventoryItemSnapshot.exists() && inventoryItemSnapshot.data().quantity > 0) {
          // Use the product ID as the key to ensure uniqueness
          productsMap[item.id] = {
            ...item, // item data from the order
            inStock: true // Mark as in stock
          };
        } else {
          // Optionally, handle items that are not in stock
          console.log(`Product ${item.productName} is out of stock.`);
        }
      }
    }

    // Convert the productsMap back into an array of its values (the unique products)
    const uniqueOrderedProducts = Object.values(productsMap);
    return uniqueOrderedProducts;
  } catch (error) {
    console.error('Error fetching previously ordered in-stock products:', error);
    throw error;
  }
};

export const uploadAndValidateID = async (selectedFile, userId, accountName, legalName, idType) => {
  try {
    const fileRef = ref(storage, `ids/${userId}/${selectedFile.name}`);
    await uploadBytes(fileRef, selectedFile);
    const fileUrl = await getDownloadURL(fileRef);

    const uploadAndValidateIDFunction = httpsCallable(functions, 'uploadAndValidateID');
    const result = await uploadAndValidateIDFunction({
      filePath: fileUrl,
      userId,
      accountName,
      legalName,
      idType,
    });

    return result.data; // { success: true/false, message: '...' }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const uploadPhoto = async (selectedFile, userId, idType) => {
  try {
    // Ensure the user is authenticated
    // const user = firebase.auth().currentUser;
    if (!userId) {
      throw new Error("User is not authenticated");
    }
    // Upload the file to Firebase Storage
    const fileRef = ref(storage, `photos/${userId}/${selectedFile.name}`);
    await uploadBytes(fileRef, selectedFile);
    const fileUrl = await getDownloadURL(fileRef);

    // Call the Cloud Function
    const uploadPhotoFunction = httpsCallable(functions, 'uploadPhoto');
    const result = await uploadPhotoFunction({
      filePath: fileUrl,
      userId,
      idType,
    });

    // Access the success and message values
    const { success, message } = result.data;

    // Update the UI or handle the result as needed
    if (success) {
      document.getElementById('result').innerText = `Success!`;
    } else {
      document.getElementById('result').innerText = `Upload failed: ${message}`;
    }

    return result.data; // { success: true, fileUrl: '...' }
  } catch (error) {
    console.error('Error in uploadPhoto:', error);
    throw new Error(error.message);
  }
};

// Add a new function to setup real-time user updates
export const setupUserListener = (userId) => {
  if (!userId) return null;
  
  return onSnapshot(doc(db, 'users', userId), (doc) => {
    if (doc.exists()) {
      const userData = doc.data();
      store.dispatch(setUser({ userId, ...userData }));
    }
  });
};