import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
// const cors = require('cors')({ origin: true });
import * as actionTypes from './actionTypes';

export const createStripeCustomer = (email, userId) => async (dispatch) => {
  const createStripeCustomerFunction = httpsCallable(functions, 'createStripeCustomer');
  try {
    const response = await createStripeCustomerFunction({ email, userId });
    const stripeCustomerId = response.data.stripeCustomerId; // Assuming the function returns the Stripe customer ID
    dispatch({ type: actionTypes.CREATE_STRIPE_CUSTOMER_SUCCESS, payload: stripeCustomerId });
    return stripeCustomerId; // Return the Stripe customer ID
  } catch (error) {
    console.error('Error creating Stripe customer:', error);
    dispatch({ type: actionTypes.CREATE_STRIPE_CUSTOMER_FAIL, payload: error });
    throw error; // Re-throw the error to be handled by the caller
  }
};
// Action to retrieve a customer's payment methods
export const getPaymentMethods = (customerId) => async (dispatch) => {
  const getPaymentMethodsFunction = httpsCallable(functions, 'getPaymentMethods');
  try {
    const { data: paymentMethods } = await getPaymentMethodsFunction({ customerId });
    dispatch({ type: actionTypes.GET_PAYMENT_METHODS_SUCCESS, payload: paymentMethods });
  } catch (error) {
    dispatch({ type: actionTypes.GET_PAYMENT_METHODS_FAIL, payload: error });
  }
};

// Action to attach a payment method to a customer
export const attachPaymentMethod = (paymentMethodId, customerId) => async (dispatch) => {
  const attachPaymentMethodFunction = httpsCallable(functions, 'attachPaymentMethod');
  try {
    const { data: attachedPaymentMethod } = await attachPaymentMethodFunction({ paymentMethodId, customerId });
    dispatch({ type: actionTypes.ATTACH_PAYMENT_METHOD_SUCCESS, payload: attachedPaymentMethod });
  } catch (error) {
    dispatch({ type: actionTypes.ATTACH_PAYMENT_METHOD_FAIL, payload: error });
  }
};

// Action to detach a payment method from a customer
export const detachPaymentMethod = (paymentMethodId) => async (dispatch) => {
  const detachPaymentMethodFunction = httpsCallable(functions, 'detachPaymentMethod');
  try {
    const { data: detachedPaymentMethod } = await detachPaymentMethodFunction({ paymentMethodId });
    dispatch({ type: actionTypes.DETACH_PAYMENT_METHOD_SUCCESS, payload: detachedPaymentMethod });
  } catch (error) {
    dispatch({ type: actionTypes.DETACH_PAYMENT_METHOD_FAIL, payload: error });
  }
};

export const setDefaultPaymentMethod = (paymentMethodId) => async (dispatch) => {
  // Logic to set the default payment method
  // This might involve calling an API endpoint or performing some async operation
  try {
    // Assume setDefaultPaymentMethodAPI is an async function that sets the default payment method
    const defaultPaymentMethod = await setDefaultPaymentMethodAPI(paymentMethodId);
    dispatch({ type: actionTypes.SET_DEFAULT_PAYMENT_METHOD_SUCCESS, payload: defaultPaymentMethod });
  } catch (error) {
    dispatch({ type: actionTypes.SET_DEFAULT_PAYMENT_METHOD_FAIL, payload: error });
  }
};

// // Action to retrieve the default payment method for a customer
// export const setDefaultPaymentMethod = (customerId) => async (dispatch) => {
//   const setDefaultPaymentMethodFunction = httpsCallable(functions, 'setDefaultPaymentMethod');
//   try {
//     const { data: defaultPaymentMethod } = await setDefaultPaymentMethodFunction({ customerId });
//     dispatch({ type: actionTypes.SET_DEFAULT_PAYMENT_METHOD_SUCCESS, payload: defaultPaymentMethod });
//   } catch (error) {
//     dispatch({ type: actionTypes.SET_DEFAULT_PAYMENT_METHOD_FAIL, payload: error });
//   }
// };

